import { gql } from "@apollo/client";

export default gql`
  query GetInitialJobList(
    $cursor: String
    $first: Int
    $orderBy: String
    $filters: Map
  ) {
    page: GetInitialJobList(
      filters: $filters
      first: $first
      after: $cursor
      orderBy: $orderBy
    ) {
      edges {
        node {
          ... on Job {
            id
            item
            action
            priority
            isEscalated
            status
            doBy
            parentChecklistTaskId
            checklists {
              checklistTasks {
                id
                checklistId
                taskType
                attachments
                label
                orderingValue
                dataText
                dataCheckboxChecked
                dataTemperatureScale
                dataDropdownValue
                dropdownOptions
                dataNumerical
                dataTemperatureValue
                required
                notes
                createdAt
                updatedAt
                childJobsIds
                dataOnOff
                dataPassFail: dataPassError
                dataYesNo
                fulfillmentAttachments
                fulfillmentNote
              }
            }
            locations {
              name
              shortDisplayName: name
              longDisplayName
              displayName
            }
            notes {
              text
              time
            }
            assignee {
              firstName
              lastName
              id
            }
            reporter {
              id
            }
            department {
              displayName
              departmentCode
            }
            attachments
            metadata {
              externalId
              source
              publicAttributes
              privateAttributes
            }
          }
        }
      }
    }
  }
`;
