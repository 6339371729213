import { gql } from "@apollo/client";
export default gql`
  mutation RemoveAdhocJobNote($adhocJobNoteId: String) {
    removeAdhocJobNote(adhocJobNoteId: $adhocJobNoteId) {
      commentor {
        employeeId
        employeeSelf
        firstName
        id
        lastName
        userName
      }
      createdAt
      id
      note
    }
  }
`;
