export default [
  { id: "jan", displayName: "January" },
  { id: "feb", displayName: "February" },
  { id: "mar", displayName: "March" },
  { id: "apr", displayName: "April" },
  { id: "may", displayName: "May" },
  { id: "jun", displayName: "June" },
  { id: "jul", displayName: "July" },
  { id: "aug", displayName: "August" },
  { id: "sep", displayName: "September" },
  { id: "oct", displayName: "October" },
  { id: "nov", displayName: "November" },
  { id: "dec", displayName: "December" }
];
