const sizes = {
  smallest: `font-size: 12px;`,
  small: `font-size: 14px;`,
  medium: `font-size: 15px;`,
  large: `font-size: 16px;`,
  largest: `font-size: 18px;`,
  extraLarge: `font-size: 22px;`,
  gigantic: `font-size: 28px`
};
const weights = {
  light: `font-weight: 300;`,
  weak: `font-weight: 400;`,
  medium: `font-weight: 500;`,
  strong: `font-weight: 600;`,
  strongest: `font-weight: 800;`
};

const type = {
  primary: `font-family: "Roboto", sans-serif;`,
  secondary: `font-family: "Lato", sans-serif;`,
  boundary: `font-family: "Montserrat", sans-serif;`
};

export default {
  sizes,
  weights,
  type
};
