import { gql } from "@apollo/client";
export default gql`
  query ProjectCycleQueuedJobDetailView(
    $projectId: ID!
    $projectCycleId: ID!
    $jobCardHash: String!
  ) {
    job: projectCycleQueuedJobDetailView(
      projectId: $projectId
      projectCycleId: $projectCycleId
      jobCardHash: $jobCardHash
    ) {
      type
      action
      item
      locations {
        id
        displayName
        isDeleted
        type
        shortDisplayName: name
        number
      }
      priority
      isEscalated
      doBy
      status
      department {
        id
        displayName
        departmentCode
      }
      role {
        id
        name
        description
      }
      reporter {
        id
        userName
        firstName
        lastName
      }
      attachments
      notes {
        createdBy {
          id
          userName
          firstName
          lastName
        }
        time
        text
      }
      createdAt
      checklistTemplate: checklist_templates {
        createdAt
        id
        checklistTasks: tasks {
          attachments: attachment
          checklistTemplateId
          createdAt
          id
          label
          notes
          orderingValue: ordering_value
          parentTaksId: parent_task_id
          required
          taskType
          updatedAt
          dataTemperatureScale
          dropdownOptions
        }
        name
        description
        updatedAt
        creator {
          firstName
          id
          lastName
        }
      }
      metadata {
        source
        jobId
        externalId
        publicAttributes {
          asset {
            id
          }
        }
        privateAttributes {
          projectCycleSvc {
            schemaVersion
            projectId
            projectCycleId
            jobCardId
          }
        }
      }
    }
  }
`;
