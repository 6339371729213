import { gql } from "@apollo/client";

export default gql`
  mutation Mutation($createAssetTypeInput: CreateAssetTypeInput!) {
    createAssetType(createAssetTypeInput: $createAssetTypeInput) {
      id
      __typename
    }
  }
`;
