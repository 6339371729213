import { gql } from "@apollo/client";

export default gql`
  query ListInvites(
    $status: InviteStatus
    $cursor: String
    $first: Int
    $orderBy: String
    $deptId: String
    $employmentTypeId: Int
    $roleId: Int
  ) {
    page: ListInvites(
      filters: {
        deptId: $deptId
        employmentTypeId: $employmentTypeId
        roleId: $roleId
        status: $status
      }
      first: $first
      after: $cursor
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        node {
          ... on Invite {
            id
            userFirstName
            userLastName
            OrgName: orgName
            orgId
            userEmail: emailAddr
            userPhone: mobileNum
            code
            roles {
              id
              deptId: deptID
              deptName
              roleId: roleID
              roleName
            }
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
