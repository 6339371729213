import { gql } from "@apollo/client";

export default gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(updateProjectInput: $input) {
      id
      displayName
    }
  }
`;
