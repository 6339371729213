import React, { useEffect, useContext, useState } from "react";
import { GET_EMPLOYEE_BY_USER_ID } from "queries";
import { useLazyQuery } from "@apollo/client";
import useSnack from "utils/useSnack";
import UserAccessContext from "contexts/UserAccessContext";
import PropertyContext from "contexts/PropertyContext";
import { Auth } from "components/Main/Authentication/AuthService";
const Context = React.createContext({});

const Provider = function(props) {
  const { setSnack, Snack } = useSnack();
  const { user } = useContext(UserAccessContext.Context);
  const { property } = useContext(PropertyContext.Context);
  const [employee, setEmployee] = useState(false);
  const [getEmployee, { data, error: employeeError }] = useLazyQuery(
    GET_EMPLOYEE_BY_USER_ID
  );

  //handle failed employee errors
  useEffect(
    _ => {
      if (user && user.id == -1) {
        setEmployee({ propertyDetails: [] });
      } else if (employeeError) {
        setSnack({
          message: "An error occurred fetching user's employment",
          error: true,
          timeout: 6000
        });
      }
    },
    [employeeError]
  );

  useEffect(
    _ => {
      if (
        user &&
        property &&
        user.id === property.userId &&
        Auth.Instance.isTokenValid(0)
      ) {
        //When property is updated, get employment
        getEmployee({
          variables: { id: user.id }
        });
      }
    },
    [property, user]
  );
  useEffect(
    _ => {
      if (data && data.employee) {
        setEmployee(data.employee);
      }
    },
    [data]
  );

  return (
    <Context.Provider
      value={{
        employee,
        resetContext: _ => {
          setEmployee(false);
        }
      }}
    >
      {props.children}
      <Snack />
    </Context.Provider>
  );
};
export default { Provider, Context };
