import { gql } from "@apollo/client";
export default gql`
  mutation updateInvite($inviteCode: String!, $input: UpdateInvite!) {
    updateInvite(inviteCode: $inviteCode, input: $input) {
      id
      code
      userFirstName
      userLastName
    }
  }
`;
