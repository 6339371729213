import React, { useEffect, useState } from "react";
import Main from "components/Main";
import { ThemeProvider } from "styled-components";
import theme from "theme";
import { ApolloProvider as Provider } from "@apollo/client";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import GraphClient from "./GraphClient";
import { ApolloProvider as OfficialProvider } from "@apollo/client";
import { QueryParamProvider } from "use-query-params";
import PropertyContext from "./contexts/PropertyContext";
import UserAccessContext from "contexts/UserAccessContext";
import EmploymentContext from "contexts/EmploymentContext";
import OnlineUsersContext from "contexts/OnlineUsersContext";
import WorkingIndicatorContext from "contexts/WorkingIndicatorContext";
import AppUrlListener from "AppUrlListener";
import { Auth } from "components/Main/Authentication/AuthService";
import GoogleAnalyticsClient from "utils/analytics/GoogleAnalyticsClient";
import ErrorBoundary from "components/shared/ErrorBoundary";
import useSnack from "utils/useSnack";
import PropertyDetailsContext from "./contexts/PropertyDetailsContext";
import {
  ConfigProvider,
  defaultTheme
} from "@optii-solutions/ui23-antd-components";
import {
  DEFAULT_THEME,
  ConfigProvider as UIProvider
} from "@optii-solutions/ui-library"; // TODO: remove all ui-23-antd-components implementations.

const RouterContainer = _ => (
  <Router>
    <App />
  </Router>
);

// Pass along auth to graphclient instances
const App = function() {
  const [graphClient, setGraphClient] = useState(false);
  let history = useHistory();
  const { setSnack, Snack } = useSnack();

  useEffect(
    _ => {
      history.listen(location => {
        GoogleAnalyticsClient.set({ page: location.pathname }); // Update the user's current page
        GoogleAnalyticsClient.pageview(location.pathname); // Record a pageview for the given page
      });
    },
    [history]
  );
  //Only setup the graph client once
  useEffect(_ => {
    setGraphClient(GraphClient(Auth.Instance));
  }, []);

  if (!graphClient) {
    return null;
  }

  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <OfficialProvider client={graphClient}>
        <Provider client={graphClient}>
          <ThemeProvider theme={theme}>
            <UserAccessContext.Provider>
              <PropertyContext.Provider>
                <PropertyDetailsContext.Provider>
                  <EmploymentContext.Provider>
                    <WorkingIndicatorContext.Provider>
                      <OnlineUsersContext.Provider>
                        <ConfigProvider theme={defaultTheme}>
                          <UIProvider theme={DEFAULT_THEME}>
                            <AppUrlListener />
                            <ErrorBoundary>
                              <Main {...{ globalSnack: setSnack }} />
                              <Snack />
                            </ErrorBoundary>
                          </UIProvider>
                        </ConfigProvider>
                      </OnlineUsersContext.Provider>
                    </WorkingIndicatorContext.Provider>
                  </EmploymentContext.Provider>
                </PropertyDetailsContext.Provider>
              </PropertyContext.Provider>
            </UserAccessContext.Provider>
          </ThemeProvider>
        </Provider>
      </OfficialProvider>
    </QueryParamProvider>
  );
};

export default RouterContainer;
