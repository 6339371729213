import { useEffect, useContext } from "react";
import PropertyContext from "contexts/PropertyContext";
import UserAccessContext from "contexts/UserAccessContext";
import ReactGA from "utils/analytics/GoogleAnalyticsClient";
import { useHistory } from "react-router-dom";

export default function useAnalytics() {
  const { user } = useContext(UserAccessContext.Context);
  const { property } = useContext(PropertyContext.Context);
  let history = useHistory();
  useEffect(
    _ => {
      history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
      });
    },
    [history]
  );
  useEffect(
    _ => {
      if (user && !property) {
        ReactGA.set({
          userId: user.id
        });
      }
      if (user && property) {
        ReactGA.set({
          userId: user.id,
          dimension1: property.id,
          dimension2: property.displayName,
          dimension3: property.orgName
        });
      }
    },
    [user, property]
  );
}
