// used only for BE filters on TEAM_MEMBER_VIEW
export default {
  in_progress: "in progress",
  new: "new",
  pending: "pending",
  completed: "completed",
  archived: "archived",
  cancelled: "cancelled",
  on_hold: "on hold"
};
