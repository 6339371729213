import { gql } from "@apollo/client";

export default gql`
  query ProjectCycleJobs(
    $projectId: ID!
    $projectCycleId: ID!
    $filters: String
  ) {
    projectCycleJobs(
      projectId: $projectId
      projectCycleId: $projectCycleId
      filters: $filters
    ) {
      totalCount
    }
  }
`;
