import { gql } from "@apollo/client";

export default gql`
  query ReportsGenerateEncryptedEmbedUrl(
    $userEmail: String
    $embedKey: String
  ) {
    url: reportsGenerateEncryptedEmbedUrl(
      userEmail: $userEmail
      embedKey: $embedKey
    )
  }
`;
