export default {
  lowest: "z-index:100;",
  prettyLow: "z-index:-1;",
  low: "z-index:1;",
  medium: "z-index:10;",
  high: "z-index:100;",
  prettyHigh: "z-index:1000;",
  highest: "z-index:10000;",
  modalBack: "z-index:10001;", // ONLY USE THIS LEVEL FOR MODAL BACKGROUND!
  modal: "z-index:10002;", // ONLY USE THIS LEVEL FOR MODAL!
  superModalBack: "z-index:10003;", // ONLY USE THIS LEVEL FOR MODAL OVER MODAL BACKGROUND!
  superModal: "z-index:10004;" // ONLY USE THIS LEVEL FOR MODAL OVER MODAL!
};
