import React, { useState, useEffect } from "react";
import config from "config";
import { GET_INITIAL_USER, GET_INVITE, ACCEPT_INVITE } from "queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "utils/useToken";
import useSnack from "utils/useSnack";
import { useTranslation } from "react-i18next";
import { Auth } from "components/Main/Authentication/AuthService";
import useSignOut from "utils/useSignOut";
import {
  AUTH_SI_ERR,
  AUTH_ERROR_USER_ID,
  AUTH_ERROR_ACCESS,
  AUTH_INVITE_ID
} from "utils/constants";
import moment from "moment";
import "moment/locale/es"; // Spanish
import "moment/locale/bg"; // Bulgarian
import "moment/locale/fr"; // French
import "moment/locale/pl"; // Polish
import "moment/locale/ro"; // Romanian
import "moment/locale/ru"; // Russian
import "moment/locale/it"; // Italian TODO: check key from BE in Preferred Language
import "moment/locale/bs"; // Bosnian
import "moment/locale/zh-cn"; // zh-CN Chinese (Simplified)
import "moment/locale/zh-tw"; // zh-TW Chinese (Traditional)
import "moment/locale/el"; // Greek
import "moment/locale/ja"; // Japanese
import "moment/locale/tl-ph"; // Tagalog (is the national language of the Philippines)
import "moment/locale/de"; // German
import "moment/locale/et"; // Estonian
import "moment/locale/pt-br"; // Portuguese Brazil
import "moment/locale/en-gb"; // English Great Britain
import "moment/locale/es-mx"; // Spanish Mexico
import "moment/locale/en-au"; // English Australia
import "moment/locale/en-nz"; // English New Zealand
import "moment/locale/uk"; // Ukrainian
import "moment/locale/tr"; // Turkish
import "moment/locale/sq"; // Albanian

import {
  htLangProperties,
  langFormats,
  mnLangProperties,
  paLangProperties
} from "utils/localizeDateConfiguration";
import MultiInviteConfirmation from "components/Dashboard/MultiInviteConfirmation";

const Context = React.createContext({});
const Provider = function(props) {
  const [token, setToken] = useToken();
  const { signOut } = useSignOut();
  const { setSnack, Snack } = useSnack();
  const [user, setUser] = useState();
  const [access, setAccess] = useState();
  const [acceptInvite] = useMutation(ACCEPT_INVITE);
  const [getInvite, { data: inviteData }] = useLazyQuery(GET_INVITE);
  const inviteId = sessionStorage.getItem(AUTH_INVITE_ID);
  // check user ids from token and get user
  const [getCurrentUser, { data, loading, error, refetch }] = useLazyQuery(
    GET_INITIAL_USER
  );
  const [confirmation, setConfirmation] = useState(false);
  const { t, i18n } = useTranslation(["common", "home"]);

  const [userId, setUserId] = useState();

  useEffect(
    _ => {
      if (data && data.user) {
        setUser(data.user);
      }
      if (data && data.access) {
        setAccess(data.access);
      }
    },
    [data]
  );
  //Existing invite acceptance
  useEffect(
    _ => {
      if (inviteData && inviteId) {
        const payLoad = {
          firstName: inviteData.user.userFirstName,
          lastName: inviteData.user.userLastName,
          emailAddr: inviteData.user.emailAddr,
          userName: inviteData.user.userName
        };

        const _shard = inviteData && inviteData.user && inviteData.user.orgId;

        const handleAcceptInvite = async () => {
          try {
            await acceptInvite({
              variables: {
                inviteCode: inviteId,
                input: payLoad
              },
              context: { _shard, _skipAuth: true }
            });
            setConfirmation(true);
            sessionStorage.removeItem(AUTH_INVITE_ID);
            // fetch the current user after an explicit delay
            // Yes this is an arbitrary timeout and there is no gaurantee that the backedn will complete processing
            //  we can work around this with polling, but it's complex with little reward
            setTimeout(_ => {
              getCurrentUser({
                variables: { id: userId }
              });
            }, 2000);
          } catch (err) {
            console.error("Couldn't create user!");
            sessionStorage.removeItem(AUTH_INVITE_ID);
            setSnack({
              message: `${t("common:Error!")} ${(err && err.message) || err}`,
              error: true,
              timeout: 5000
            });
          }
        };
        handleAcceptInvite();
        // TODO: Make sure the user thats logged in is the user the invite is for before accepting it
        // if (user.userName === inviteData.user.userName) {
        //   handleAcceptInvite();
        // } else {
        //   sessionStorage.removeItem(AUTH_INVITE_ID);
        // }
      }
    },
    [inviteData]
  );
  useEffect(
    _ => {
      // Don't get user if token is expired or data is already set
      if (token && Auth.Instance.isTokenValid(0)) {
        let userId;
        switch (config.ENV) {
          case "DEV":
            userId = token.devOptiiUserId;
            break;
          case "TEST":
            userId = token.testOptiiUserId;
            break;
          default:
            userId = token.optiiUserId;
        }
        let optiiProperty =
          localStorage.getItem("optiiProperty") &&
          JSON.parse(localStorage.getItem("optiiProperty"));

        if (
          optiiProperty &&
          optiiProperty.userId &&
          Number(optiiProperty.userId) !== Number(userId)
        ) {
          localStorage.removeItem("optiiProperty");
          sessionStorage.removeItem("optiiProperty");
        }
        //if user has an existing invite, we accept it first
        if (userId && inviteId && inviteId !== "normal") {
          setUserId(userId);
          getInvite({
            variables: { code: inviteId },
            context: { _skipAuth: true }
          });
        } else if (userId) {
          getCurrentUser({
            variables: { id: userId }
          });
        } else {
          //Didn't find a valid id for this env, logout
          console.error("No optii user ID found on token");
          if (!sessionStorage.getItem(AUTH_SI_ERR)) {
            sessionStorage.setItem(AUTH_SI_ERR, AUTH_ERROR_USER_ID);
            signOut();
          }
        }
      }
    },
    [token]
  );

  useEffect(() => {
    if (data && data.user) {
      i18n.changeLanguage(data.user.preferredLang);
      const currentLang = data.user.preferredLang.toLowerCase();
      moment.locale(currentLang);

      if (currentLang === "pa") {
        // adding Punjabi language as moment doesn't support it.
        moment.updateLocale("pa", paLangProperties);
      }

      if (currentLang === "ht") {
        // adding Haitian Creole language as moment doesn't support it
        moment.defineLocale("ht", htLangProperties);
      }
      if (currentLang === "mn") {
        // adding Mongolian Language as moment doesn't support it
        moment.defineLocale("mn", mnLangProperties);
      }

      if (langFormats[currentLang]) {
        moment.updateLocale(currentLang, {
          longDateFormat: langFormats[currentLang]
        });
      } else {
        moment.updateLocale(currentLang, {
          longDateFormat: langFormats["other"]
        });
      }
    }
  }, [i18n, data]);

  // Set up user's access
  useEffect(
    _ => {
      const access = data && data.access;
      if (access) {
        // If there are no properties the user is associated with
        // log them out for now
        if (!access.Access.Properties || !access.Access.Properties.length) {
          console.error("No Properties found on user's access");
          sessionStorage.setItem(AUTH_SI_ERR, AUTH_ERROR_ACCESS);
          signOut();
        }
      }
    },
    [data]
  );

  //handle failed employee errors
  useEffect(
    _ => {
      if (error) {
        setSnack({
          message: "An error occurred fetching user's access and data",
          error: true,
          timeout: 6000
        });
      }
    },
    [error]
  );
  return (
    <Context.Provider
      value={{
        user,
        access,
        refetch,
        loading,
        error,
        token,
        setToken,
        getLatestUser: _ =>
          getCurrentUser({
            variables: { id: data.user.id }
          }),
        resetContext: _ => {
          setUser(null);
          setAccess(null);
        }
      }}
    >
      {props.children}
      {confirmation && (
        <MultiInviteConfirmation
          hide={_ => setConfirmation(false)}
          confirm={_ => setConfirmation(false)}
          firstName={inviteData.user.userFirstName}
          orgName={inviteData.user.orgName}
          title={t("home:Invite Accepted")}
        />
      )}
      <Snack />
    </Context.Provider>
  );
};

export default { Provider, Context };
