import { gql } from "@apollo/client";

export default gql`
  query GetJobDefault($action: JobAction!, $item: String!) {
    defaults: GetJobDefault(action: $action, item: $item) {
      department {
        id
        displayName
        departmentCode
      }
      role {
        id
        name
        description
      }
    }
  }
`;
