export default [
  { id: "AFG", displayName: "Afghanistan" },
  { id: "ALA", displayName: "Åland Islands" },
  { id: "ALB", displayName: "Albania" },
  { id: "DZA", displayName: "Algeria" },
  { id: "ASM", displayName: "American Samoa" },
  { id: "AND", displayName: "Andorra" },
  { id: "AGO", displayName: "Angola" },
  { id: "AIA", displayName: "Anguilla" },
  { id: "ATA", displayName: "Antarctica" },
  { id: "ATG", displayName: "Antigua and Barbuda" },
  { id: "ARG", displayName: "Argentina" },
  { id: "ARM", displayName: "Armenia" },
  { id: "ABW", displayName: "Aruba" },
  { id: "AUS", displayName: "Australia" },
  { id: "AUT", displayName: "Austria" },
  { id: "AZE", displayName: "Azerbaijan" },
  { id: "BHS", displayName: "Bahamas" },
  { id: "BHR", displayName: "Bahrain" },
  { id: "BGD", displayName: "Bangladesh" },
  { id: "BRB", displayName: "Barbados" },
  { id: "BLR", displayName: "Belarus" },
  { id: "BEL", displayName: "Belgium" },
  { id: "BLZ", displayName: "Belize" },
  { id: "BEN", displayName: "Benin" },
  { id: "BMU", displayName: "Bermuda" },
  { id: "BTN", displayName: "Bhutan" },
  { id: "BOL", displayName: "Bolivia, Plurinational State of" },
  { id: "BES", displayName: "Bonaire, Sint Eustatius and Saba" },
  { id: "BIH", displayName: "Bosnia and Herzegovina" },
  { id: "BWA", displayName: "Botswana" },
  { id: "BVT", displayName: "Bouvet Island" },
  { id: "BRA", displayName: "Brazil" },
  { id: "IOT", displayName: "British Indian Ocean Territory" },
  { id: "BRN", displayName: "Brunei Darussalam" },
  { id: "BGR", displayName: "Bulgaria" },
  { id: "BFA", displayName: "Burkina Faso" },
  { id: "BDI", displayName: "Burundi" },
  { id: "KHM", displayName: "Cambodia" },
  { id: "CMR", displayName: "Cameroon" },
  { id: "CAN", displayName: "Canada" },
  { id: "CPV", displayName: "Cape Verde" },
  { id: "CYM", displayName: "Cayman Islands" },
  { id: "CAF", displayName: "Central African Republic" },
  { id: "TCD", displayName: "Chad" },
  { id: "CHL", displayName: "Chile" },
  { id: "CHN", displayName: "China" },
  { id: "CXR", displayName: "Christmas Island" },
  { id: "CCK", displayName: "Cocos (Keeling) Islands" },
  { id: "COL", displayName: "Colombia" },
  { id: "COM", displayName: "Comoros" },
  { id: "COG", displayName: "Congo" },
  { id: "COD", displayName: "Congo, the Democratic Republic of the" },
  { id: "COK", displayName: "Cook Islands" },
  { id: "CRI", displayName: "Costa Rica" },
  { id: "CIV", displayName: "Côte d'Ivoire" },
  { id: "HRV", displayName: "Croatia" },
  { id: "CUB", displayName: "Cuba" },
  { id: "CUW", displayName: "Curaçao" },
  { id: "CYP", displayName: "Cyprus" },
  { id: "CZE", displayName: "Czech Republic" },
  { id: "DNK", displayName: "Denmark" },
  { id: "DJI", displayName: "Djibouti" },
  { id: "DMA", displayName: "Dominica" },
  { id: "DOM", displayName: "Dominican Republic" },
  { id: "ECU", displayName: "Ecuador" },
  { id: "EGY", displayName: "Egypt" },
  { id: "SLV", displayName: "El Salvador" },
  { id: "GNQ", displayName: "Equatorial Guinea" },
  { id: "ERI", displayName: "Eritrea" },
  { id: "EST", displayName: "Estonia" },
  { id: "ETH", displayName: "Ethiopia" },
  { id: "FLK", displayName: "Falkland Islands (Malvinas)" },
  { id: "FRO", displayName: "Faroe Islands" },
  { id: "FJI", displayName: "Fiji" },
  { id: "FIN", displayName: "Finland" },
  { id: "FRA", displayName: "France" },
  { id: "GUF", displayName: "French Guiana" },
  { id: "PYF", displayName: "French Polynesia" },
  { id: "ATF", displayName: "French Southern Territories" },
  { id: "GAB", displayName: "Gabon" },
  { id: "GMB", displayName: "Gambia" },
  { id: "GEO", displayName: "Georgia" },
  { id: "DEU", displayName: "Germany" },
  { id: "GHA", displayName: "Ghana" },
  { id: "GIB", displayName: "Gibraltar" },
  { id: "GRC", displayName: "Greece" },
  { id: "GRL", displayName: "Greenland" },
  { id: "GRD", displayName: "Grenada" },
  { id: "GLP", displayName: "Guadeloupe" },
  { id: "GUM", displayName: "Guam" },
  { id: "GTM", displayName: "Guatemala" },
  { id: "GGY", displayName: "Guernsey" },
  { id: "GIN", displayName: "Guinea" },
  { id: "GNB", displayName: "Guinea-Bissau" },
  { id: "GUY", displayName: "Guyana" },
  { id: "HTI", displayName: "Haiti" },
  { id: "HMD", displayName: "Heard Island and McDonald Islands" },
  { id: "VAT", displayName: "Holy See (Vatican City State)" },
  { id: "HND", displayName: "Honduras" },
  { id: "HKG", displayName: "Hong Kong" },
  { id: "HUN", displayName: "Hungary" },
  { id: "ISL", displayName: "Iceland" },
  { id: "IND", displayName: "India" },
  { id: "IDN", displayName: "Indonesia" },
  { id: "IRN", displayName: "Iran, Islamic Republic of" },
  { id: "IRQ", displayName: "Iraq" },
  { id: "IRL", displayName: "Ireland" },
  { id: "IMN", displayName: "Isle of Man" },
  { id: "ISR", displayName: "Israel" },
  { id: "ITA", displayName: "Italy" },
  { id: "JAM", displayName: "Jamaica" },
  { id: "JPN", displayName: "Japan" },
  { id: "JEY", displayName: "Jersey" },
  { id: "JOR", displayName: "Jordan" },
  { id: "KAZ", displayName: "Kazakhstan" },
  { id: "KEN", displayName: "Kenya" },
  { id: "KIR", displayName: "Kiribati" },
  { id: "PRK", displayName: "Korea, Democratic People's Republic of" },
  { id: "KOR", displayName: "Korea, Republic of" },
  { id: "KWT", displayName: "Kuwait" },
  { id: "KGZ", displayName: "Kyrgyzstan" },
  { id: "LAO", displayName: "Lao People's Democratic Republic" },
  { id: "LVA", displayName: "Latvia" },
  { id: "LBN", displayName: "Lebanon" },
  { id: "LSO", displayName: "Lesotho" },
  { id: "LBR", displayName: "Liberia" },
  { id: "LBY", displayName: "Libya" },
  { id: "LIE", displayName: "Liechtenstein" },
  { id: "LTU", displayName: "Lithuania" },
  { id: "LUX", displayName: "Luxembourg" },
  { id: "MAC", displayName: "Macau" },
  { id: "MKD", displayName: "Macedonia, the former Yugoslav Republic of" },
  { id: "MDG", displayName: "Madagascar" },
  { id: "MWI", displayName: "Malawi" },
  { id: "MYS", displayName: "Malaysia" },
  { id: "MDV", displayName: "Maldives" },
  { id: "MLI", displayName: "Mali" },
  { id: "MLT", displayName: "Malta" },
  { id: "MHL", displayName: "Marshall Islands" },
  { id: "MTQ", displayName: "Martinique" },
  { id: "MRT", displayName: "Mauritania" },
  { id: "MUS", displayName: "Mauritius" },
  { id: "MYT", displayName: "Mayotte" },
  { id: "MEX", displayName: "Mexico" },
  { id: "FSM", displayName: "Micronesia, Federated States of" },
  { id: "MDA", displayName: "Moldova, Republic of" },
  { id: "MCO", displayName: "Monaco" },
  { id: "MNG", displayName: "Mongolia" },
  { id: "MNE", displayName: "Montenegro" },
  { id: "MSR", displayName: "Montserrat" },
  { id: "MAR", displayName: "Morocco" },
  { id: "MOZ", displayName: "Mozambique" },
  { id: "MMR", displayName: "Myanmar" },
  { id: "NAM", displayName: "Namibia" },
  { id: "NRU", displayName: "Nauru" },
  { id: "NPL", displayName: "Nepal" },
  { id: "NLD", displayName: "Netherlands" },
  { id: "NCL", displayName: "New Caledonia" },
  { id: "NZL", displayName: "New Zealand" },
  { id: "NIC", displayName: "Nicaragua" },
  { id: "NER", displayName: "Niger" },
  { id: "NGA", displayName: "Nigeria" },
  { id: "NIU", displayName: "Niue" },
  { id: "NFK", displayName: "Norfolk Island" },
  { id: "MNP", displayName: "Northern Mariana Islands" },
  { id: "NOR", displayName: "Norway" },
  { id: "OMN", displayName: "Oman" },
  { id: "PAK", displayName: "Pakistan" },
  { id: "PLW", displayName: "Palau" },
  { id: "PSE", displayName: "Palestinian Territory, Occupied" },
  { id: "PAN", displayName: "Panama" },
  { id: "PNG", displayName: "Papua New Guinea" },
  { id: "PRY", displayName: "Paraguay" },
  { id: "PER", displayName: "Peru" },
  { id: "PHL", displayName: "Philippines" },
  { id: "PCN", displayName: "Pitcairn" },
  { id: "POL", displayName: "Poland" },
  { id: "PRT", displayName: "Portugal" },
  { id: "PRI", displayName: "Puerto Rico" },
  { id: "QAT", displayName: "Qatar" },
  { id: "REU", displayName: "Réunion" },
  { id: "ROU", displayName: "Romania" },
  { id: "RUS", displayName: "Russian Federation" },
  { id: "RWA", displayName: "Rwanda" },
  { id: "BLM", displayName: "Saint Barthélemy" },
  { id: "SHN", displayName: "Saint Helena, Ascension and Tristan da Cunha" },
  { id: "KNA", displayName: "Saint Kitts and Nevis" },
  { id: "LCA", displayName: "Saint Lucia" },
  { id: "MAF", displayName: "Saint Martin (French part)" },
  { id: "SPM", displayName: "Saint Pierre and Miquelon" },
  { id: "VCT", displayName: "Saint Vincent and the Grenadines" },
  { id: "WSM", displayName: "Samoa" },
  { id: "SMR", displayName: "San Marino" },
  { id: "STP", displayName: "Sao Tome and Principe" },
  { id: "SAU", displayName: "Saudi Arabia" },
  { id: "SEN", displayName: "Senegal" },
  { id: "SRB", displayName: "Serbia" },
  { id: "SYC", displayName: "Seychelles" },
  { id: "SLE", displayName: "Sierra Leone" },
  { id: "SGP", displayName: "Singapore" },
  { id: "SXM", displayName: "Sint Maarten (Dutch part)" },
  { id: "SVK", displayName: "Slovakia" },
  { id: "SVN", displayName: "Slovenia" },
  { id: "SLB", displayName: "Solomon Islands" },
  { id: "SOM", displayName: "Somalia" },
  { id: "ZAF", displayName: "South Africa" },
  { id: "SGS", displayName: "South Georgia and the South Sandwich Islands" },
  { id: "SSD", displayName: "South Sudan" },
  { id: "ESP", displayName: "Spain" },
  { id: "LKA", displayName: "Sri Lanka" },
  { id: "SDN", displayName: "Sudan" },
  { id: "SUR", displayName: "Suriname" },
  { id: "SJM", displayName: "Svalbard and Jan Mayen" },
  { id: "SWZ", displayName: "Swaziland" },
  { id: "SWE", displayName: "Sweden" },
  { id: "CHE", displayName: "Switzerland" },
  { id: "SYR", displayName: "Syrian Arab Republic" },
  { id: "TWN", displayName: "Taiwan, Province of China" },
  { id: "TJK", displayName: "Tajikistan" },
  { id: "TZA", displayName: "Tanzania, United Republic of" },
  { id: "THA", displayName: "Thailand" },
  { id: "TLS", displayName: "Timor-Leste" },
  { id: "TGO", displayName: "Togo" },
  { id: "TKL", displayName: "Tokelau" },
  { id: "TON", displayName: "Tonga" },
  { id: "TTO", displayName: "Trinidad and Tobago" },
  { id: "TUN", displayName: "Tunisia" },
  { id: "TUR", displayName: "Turkey" },
  { id: "TKM", displayName: "Turkmenistan" },
  { id: "TCA", displayName: "Turks and Caicos Islands" },
  { id: "TUV", displayName: "Tuvalu" },
  { id: "UGA", displayName: "Uganda" },
  { id: "UKR", displayName: "Ukraine" },
  { id: "ARE", displayName: "United Arab Emirates" },
  { id: "GBR", displayName: "United Kingdom" },
  { id: "USA", displayName: "United States" },
  { id: "UMI", displayName: "United States Minor Outlying Islands" },
  { id: "URY", displayName: "Uruguay" },
  { id: "UZB", displayName: "Uzbekistan" },
  { id: "VUT", displayName: "Vanuatu" },
  { id: "VEN", displayName: "Venezuela, Bolivarian Republic of" },
  { id: "VNM", displayName: "Viet Nam" },
  { id: "VGB", displayName: "Virgin Islands, British" },
  { id: "VIR", displayName: "Virgin Islands, U.S." },
  { id: "WLF", displayName: "Wallis and Futuna" },
  { id: "ESH", displayName: "Western Sahara" },
  { id: "YEM", displayName: "Yemen" },
  { id: "ZMB", displayName: "Zambia" },
  { id: "ZWE", displayName: "Zimbabwe" }
];
