import { gql } from "@apollo/client";
//TODO: products
export default gql`
  query GetPropertyById($id: ID!) {
    property: GetPropertyById(id: $id) {
      id
      orgId
      orgName
      ownerId
      ownerName
      mgmtCompanyId
      mgmtCompanyName
      displayName
      internalRefCode
      externalRefCode
      licensedRoomCount
      useLocationOption
      budgetValue
      products
      status
      operationsContact {
        firstName
        lastName
        phone
        email
      }
      location {
        country
        address
        address2
        city
        state
        postalCode
      }
      billingContact {
        firstName
        lastName
        phone
        email
      }
      billingLocation {
        country
        address
        address2
        city
        state
        postalCode
      }
      regionalSettings {
        logoURL
        language
        timeZone
        startOfWeek
        startOfFiscalYear
      }
    }
  }
`;
