import { gql } from "@apollo/client";

export default gql`
  query AssetType($assetTypeId: ID!) {
    assetType(id: $assetTypeId) {
      displayName
      description
      status
      version
    }
  }
`;
