export default {
  xxs: "391px",
  xs: "479px",
  sm: "681px", // The lenovo is 600 px exactly :p
  smMax: "682px",
  md: "900px",
  mdMax: "901px",
  mdMax902: "902px", //
  miniLg: "1104px",
  miniLgMax: "1105px",
  lg: "1265px",
  bg: "1400px"
};
