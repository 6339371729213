import { gql } from "@apollo/client";

export default gql`
  mutation AssignProjectCycleJob(
    $projectId: ID!
    $projectCycleId: ID!
    $assignProjectCycleJobInput: AssignProjectCycleJobInput!
  ) {
    assignProjectCycleJob(
      projectId: $projectId
      projectCycleId: $projectCycleId
      assignProjectCycleJobInput: $assignProjectCycleJobInput
    ) {
      id
      asset {
        id
        assetType {
          id
        }
      }
      status
      assignee {
        employee {
          id
        }
        displayName
      }
      job {
        id
      }
      locations {
        locationId
        displayName
      }
    }
  }
`;
