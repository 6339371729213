const gaEvent = {
  engineering: {
    category: "_engineering",
    actions: {
      forgotPassword: "Forgot Password"
    }
  },
  jobs: {
    category: "Jobs",
    actions: {
      add: "Add",
      view: "View",
      edit: "Edit",
      delete: "Delete",
      take: "Take",
      start: "Start",
      takeAndStart: "Take and Start",
      cancel: "Cancel",
      pause: "Pause",
      complete: "Complete",
      addNote: "Add Note",
      viewActivity: "View Activity",
      addFile: "Add File",
      filter: "Filter",
      sort: "Sort",
      search: "Search",
      undelete: "Undelete"
    }
  },
  login: {
    category: "Login",
    actions: {
      login: "Login",
      troubleLoggingIn: "Trouble Logging In?",
      quickAccessCode: "Have a Quick Access Code?",
      tos: "Terms of Service"
    }
  },
  logout: {
    category: "Logout",
    actions: {
      loggedOut: "Logged Out"
    }
  },
  chat: {
    category: "Chat",
    actions: {
      sendMessage: "Send",
      tagUser: "Tag",
      delete: "Delete",
      directMessage: "Add",
      badgeCount: "Badge Count"
    }
  },
  userProfile: {
    category: "User Profile",
    actions: {
      viewPersonalInfo: "View Personal Information",
      editGeneral: "Edit General",
      editContact: "Edit Contact Details",
      editLanguage: "Edit Language",
      editEmployment: "Edit Employment",
      editPassword: "Edit Password"
    }
  },
  help: {
    category: "Help",
    actions: {
      helpVideo: "visit our Learning Center",
      helpLink: "Learning Center",
      menuHelp: "Get Help"
    }
  },
  misc: {
    category: "Miscellaneous",
    actions: {
      unknownFileUpload: "File Upload"
    }
  },
  escalations: {
    category: "Escalations",
    actions: {
      add: "Add",
      view: "View",
      edit: "Edit",
      delete: "Delete",
      search: "Search"
    }
  }
};

export default {
  searchEscalation: {
    action: gaEvent.escalations.actions.search,
    category: gaEvent.escalations.category,
    label: "Typed in Search field"
  },
  deleteEscalationConfirm: {
    action: gaEvent.escalations.actions.delete,
    category: gaEvent.escalations.category,
    label: "Confirmed deleting escalation"
  },
  deleteIconEscalation: {
    action: gaEvent.escalations.actions.delete,
    category: gaEvent.escalations.category,
    label: "Clicked Delete icon"
  },
  editEscalationCancel: {
    action: gaEvent.escalations.actions.edit,
    category: gaEvent.escalations.category,
    label: "Clicked Cancel on edit escalation form"
  },
  editEscalationUpdate: {
    action: gaEvent.escalations.actions.edit,
    category: gaEvent.escalations.category,
    label: "Clicked Update on edit escalation form"
  },
  editIconEscalation: {
    action: gaEvent.escalations.actions.edit,
    category: gaEvent.escalations.category,
    label: "Clicked Edit icon"
  },
  saveEscalationCancel: {
    action: gaEvent.escalations.actions.add,
    category: gaEvent.escalations.category,
    label: "Clicked Cancel on add escalation form"
  },
  saveEscalation: {
    action: gaEvent.escalations.actions.add,
    category: gaEvent.escalations.category,
    label: "Clicked Save on add escalation form"
  },
  addEscalation: {
    action: gaEvent.escalations.actions.add,
    category: gaEvent.escalations.category,
    label: "Clicked Add Escalation button"
  },
  viewEscalations: {
    action: gaEvent.escalations.actions.view,
    category: gaEvent.escalations.category,
    label: "Clicked Escalations in Settings > Job Rules"
  },
  createJob: {
    action: gaEvent.jobs.actions.add,
    category: gaEvent.jobs.category,
    label: "Clicked Save on Job Status - Add Job"
  },
  viewJobSwimLane: {
    action: gaEvent.jobs.actions.view,
    category: gaEvent.jobs.category,
    label: "Clicked on card in Job Status"
  },
  editJobCardSwimLane: {
    action: gaEvent.jobs.actions.edit,
    category: gaEvent.jobs.category,
    label: "Clicked edit on card in Job Status"
  },
  editJobModal: {
    action: gaEvent.jobs.actions.edit,
    category: gaEvent.jobs.category,
    label: "Clicked edit icon on job modal"
  },
  editJobModalCancel: {
    action: gaEvent.jobs.actions.edit,
    category: gaEvent.jobs.category,
    label: "Clicked edit on Job Detail View to cancel edit"
  },
  editJobModalCancelButton: {
    action: gaEvent.jobs.actions.edit,
    category: gaEvent.jobs.category,
    label: "Clicked Cancel on Job Edit View"
  },
  editJobModalUpdateButton: {
    action: gaEvent.jobs.actions.edit,
    category: gaEvent.jobs.category,
    label: "Clicked Update on Job Edit View"
  },
  deleteJobCardSwimLane: {
    action: gaEvent.jobs.actions.delete,
    category: gaEvent.jobs.category,
    label: "Clicked delete on card in Job Status"
  },
  deleteQueuedJob: {
    action: gaEvent.jobs.actions.delete,
    category: gaEvent.jobs.category,
    label: "Clicked delete on card in Job Cycle"
  },
  deleteJobConfirmButton: {
    action: gaEvent.jobs.actions.delete,
    category: gaEvent.jobs.category,
    label: "Confirmed deleting a job"
  },
  takeJob: {
    action: gaEvent.jobs.actions.take,
    category: gaEvent.jobs.category,
    label: "Clicked Take on Job Detail View"
  },
  startJob: {
    action: gaEvent.jobs.actions.start,
    category: gaEvent.jobs.category,
    label: "Clicked Start on Job Detail View"
  },
  takeAndStartJob: {
    action: gaEvent.jobs.actions.takeAndStart,
    category: gaEvent.jobs.category,
    label: "Clicked Take and Start on Job Detail View"
  },
  cancelJob: {
    action: gaEvent.jobs.actions.cancel,
    category: gaEvent.jobs.category,
    label: "Clicked Cancel Job on Job Detail View"
  },
  cancelJobConfirm: {
    action: gaEvent.jobs.actions.cancel,
    category: gaEvent.jobs.category,
    label: "Confirmed cancelling a job"
  },
  pauseJob: {
    action: gaEvent.jobs.actions.pause,
    category: gaEvent.jobs.category,
    label: "Clicked Pause on Job Detail View"
  },
  completeJob: {
    action: gaEvent.jobs.actions.complete,
    category: gaEvent.jobs.category,
    label: "Clicked Complete on Job Detail View"
  },
  addNoteJob: {
    action: gaEvent.jobs.actions.addNote,
    category: gaEvent.jobs.category,
    label: "Note added from Job Detail View"
  },
  viewActivityJob: {
    action: gaEvent.jobs.actions.viewActivity,
    category: gaEvent.jobs.category,
    label: "Clicked Activity on Job Detail View"
  },
  addFileJob: {
    action: gaEvent.jobs.actions.addFile,
    category: gaEvent.jobs.category,
    label: "Clicked Upload File on Job Detail View"
  },
  filterJobDepartment: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Department in Job Status"
  },
  filterJobRole: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Role in Job Status"
  },
  filterPastDue: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Past Due in Job Status"
  },
  filterJobTypeProject: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Project Jobs in Job Status"
  },
  filterJobTypeRepeating: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Repeating Jobs in Job Status"
  },
  filterJobTypeStandalone: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Standalone Jobs in Job Status"
  },
  orderByDepartmentAsc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Department: A-Z in Job Status"
  },
  orderByDepartmentDesc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Department: Z-A in Job Status"
  },
  orderByTargetTimeAsc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Due By: Earliest in Job Status"
  },
  orderByTargetTimeDesc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Due By: Latest in Job Status"
  },
  orderByPriorityAsc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Priority: Low to Highest in Job Status"
  },
  orderByPriorityDesc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Priority: Highest to Low in Job Status"
  },
  orderByFirstNameAsc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Team Member: A-Z in Job Status"
  },
  orderByFirstNameDesc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Team Member: Z-A in Job Status"
  },
  filterJobSearch: {
    action: gaEvent.jobs.actions.search,
    category: gaEvent.jobs.category,
    label: "Search Job in Job Status"
  },
  loggingIn: {
    action: gaEvent.login.actions.login,
    category: gaEvent.login.category,
    label: "Clicked Login"
  },
  loginTrouble: {
    action: gaEvent.login.actions.troubleLoggingIn,
    category: gaEvent.login.category,
    label: "Clicked Trouble Logging In?"
  },
  requestResetPassword: {
    action: gaEvent.login.actions.troubleLoggingIn,
    category: gaEvent.login.category,
    label: "Clicked Continue to Request Reset Password Email"
  },
  requestResetPasswordGoBackToLogin: {
    action: gaEvent.login.actions.troubleLoggingIn,
    category: gaEvent.login.category,
    label: "Clicked Go Back to Login"
  },
  haveQuickAccessCode: {
    action: gaEvent.login.actions.quickAccessCode,
    category: gaEvent.login.category,
    label: "Clicked Have a Quick Access Code"
  },
  initiateCreateProfile: {
    action: gaEvent.login.actions.quickAccessCode,
    category: gaEvent.login.category,
    label: "Clicked Continue to Create Profile"
  },
  termsOfService: {
    action: gaEvent.login.actions.quickAccessCode,
    category: gaEvent.login.category,
    label: "Clicked Terms of Service"
  },
  loggedOut: {
    action: gaEvent.logout.actions.loggedOut,
    category: gaEvent.logout.category,
    label: "Clicked Logout"
  },
  sendMessageAllMembers: {
    action: gaEvent.chat.actions.sendMessage,
    category: gaEvent.chat.category,
    label: "Send Chat To All Team Members"
  },
  sendMessageDepartment: {
    action: gaEvent.chat.actions.sendMessage,
    category: gaEvent.chat.category,
    label: "Send Chat To Department Channels"
  },
  sendMessageDirect: {
    action: gaEvent.chat.actions.sendMessage,
    category: gaEvent.chat.category,
    label: "Send Direct Messages"
  },
  tagUser: {
    action: gaEvent.chat.actions.tagUser,
    category: gaEvent.chat.category,
    label: "Tag Team Member"
  },
  deleteDirectMessage: {
    action: gaEvent.chat.actions.delete,
    category: gaEvent.chat.category,
    label: "Clicked delete on Direct Message"
  },
  deleteDirectMessageConfirm: {
    action: gaEvent.chat.actions.delete,
    category: gaEvent.chat.category,
    label: "Confirmed deleting a Direct message"
  },
  addDirectMessage: {
    action: gaEvent.chat.actions.directMessage,
    category: gaEvent.chat.category,
    label: "Add Direct Message"
  },
  directMessageTeamMemberSearch: {
    action: gaEvent.chat.actions.directMessage,
    category: gaEvent.chat.category,
    label: "Search Team Members in Chat"
  },
  directMessageTeamMemberSelect: {
    action: gaEvent.chat.actions.directMessage,
    category: gaEvent.chat.category,
    label: "Select Team Member"
  },
  badgeCount: {
    action: gaEvent.chat.actions.badgeCount,
    category: gaEvent.chat.category,
    label: "Clicked on Global Badge Count"
  },
  forgotPasswordLog: {
    action: gaEvent.engineering.actions.forgotPassword,
    category: gaEvent.engineering.category,
    label: ""
  },
  viewPersonalInfo: {
    action: gaEvent.userProfile.actions.viewPersonalInfo,
    category: gaEvent.userProfile.category,
    label: "Click on personal information"
  },
  editGeneral: {
    action: gaEvent.userProfile.actions.editGeneral,
    category: gaEvent.userProfile.category,
    label: "Clicked edit on General"
  },
  cancelGeneral: {
    action: gaEvent.userProfile.actions.editGeneral,
    category: gaEvent.userProfile.category,
    label: "Clicked Cancel on General"
  },
  updateGeneral: {
    action: gaEvent.userProfile.actions.editGeneral,
    category: gaEvent.userProfile.category,
    label: "Clicked Update on General"
  },
  editContact: {
    action: gaEvent.userProfile.actions.editContact,
    category: gaEvent.userProfile.category,
    label: "Clicked edit on Contact Details"
  },
  cancelContact: {
    action: gaEvent.userProfile.actions.editContact,
    category: gaEvent.userProfile.category,
    label: "Clicked Cancel on Contact Details"
  },
  updateContact: {
    action: gaEvent.userProfile.actions.editContact,
    category: gaEvent.userProfile.category,
    label: "Clicked Update on Contact Details"
  },
  editLanguage: {
    action: gaEvent.userProfile.actions.editLanguage,
    category: gaEvent.userProfile.category,
    label: "Clicked edit on Language"
  },
  cancelLanguage: {
    action: gaEvent.userProfile.actions.editLanguage,
    category: gaEvent.userProfile.category,
    label: "Clicked Cancel on Language"
  },
  updateLanguage: {
    action: gaEvent.userProfile.actions.editLanguage,
    category: gaEvent.userProfile.category,
    label: "Clicked Update on Language"
  },
  viewEmployment: {
    action: gaEvent.userProfile.actions.editEmployment,
    category: gaEvent.userProfile.category,
    label: "Clicked view on Employment"
  },
  cancelEmployment: {
    action: gaEvent.userProfile.actions.editEmployment,
    category: gaEvent.userProfile.category,
    label: "Clicked Cancel on Employment"
  },
  editEmployment: {
    action: gaEvent.userProfile.actions.editEmployment,
    category: gaEvent.userProfile.category,
    label: "Clicked Edit on Employment"
  },
  updateEmployment: {
    action: gaEvent.userProfile.actions.editEmployment,
    category: gaEvent.userProfile.category,
    label: "Clicked Update on Employment"
  },
  addEmployment: {
    action: gaEvent.userProfile.actions.editEmployment,
    category: gaEvent.userProfile.category,
    label: "Clicked Add Another Position"
  },
  deleteEmployment: {
    action: gaEvent.userProfile.actions.editEmployment,
    category: gaEvent.userProfile.category,
    label: "Clicked Delete Position"
  },
  editPassword: {
    action: gaEvent.userProfile.actions.editPassword,
    category: gaEvent.userProfile.category,
    label: "Clicked Save Password"
  },
  dashHelpVideo: {
    action: gaEvent.help.actions.helpVideo,
    category: gaEvent.help.category,
    label: "Clicked Dashboard Help Video Link"
  },
  dashHelpLink: {
    action: gaEvent.help.actions.helpLink,
    category: gaEvent.help.category,
    label: "Clicked Dashboard Help Shortcut"
  },
  helpMenu: {
    action: gaEvent.help.actions.menuHelp,
    category: gaEvent.help.category,
    label: "Clicked Menu Get Help Link"
  },
  uploadFileUnknownSource: {
    action: gaEvent.misc.actions.unknownFileUpload,
    category: gaEvent.misc.category,
    label: "File uploaded from unknown component"
  },
  undeleteDeletedJob: {
    action: gaEvent.jobs.actions.delete,
    category: gaEvent.jobs.category,
    label: "Clicked undelete on card in Job Cycle"
  }
};
