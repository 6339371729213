import { gql } from "@apollo/client";

export default gql`
  query GetAllLocationTypes {
    locationTypes: GetAllLocationTypes {
      id
      displayName
      code
      squareUnit
      note
    }
  }
`;
