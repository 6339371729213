import styled from "styled-components/macro";
import React from "react";
import BaseButton from "./BaseButton";

const Button = styled(BaseButton)`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.rose};

  :active,
  :hover {
    ${props => props.theme.shadows.button}
  }

  :active {
    background-color: ${props => props.theme.colors.roseXDark};
  }
`;
function CautionButton(props) {
  return <Button {...props}>{props.children}</Button>;
}
export default CautionButton;
