import { ApolloClient, split } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "@apollo/client/cache";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { WebSocketLink as wsLink } from "utils/WebSocketLink";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import config from "config";
import { getAuthHeaders } from "utils/getAuthHeaders";
import { v4 as uuidv4 } from "uuid";

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map(response => {
        const { message, locations, path } = response;
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        // If we have an auth error, do something???
        if (message === "ERR_NOT_ALLOWED") {
          console.error("NOT ALLOWED ERROR!!!");
        }
        //Message: [ERR_UNAUTHORIZED] Invalid Bearer Token
        if (message.match(/Invalid Bearer Token/)) {
          // clear the token then reload the page
          // window.location.reload(true);
        }
      });
    } else if (networkError) {
      console.log(
        `[Network error in ${operation.operationName}]:`,
        networkError
      );
      if (
        networkError.indexOf &&
        networkError.indexOf(
          "Origin capacitor://localhost is not allowed by Access-Control-Allow-Origin."
        ) !== -1
      ) {
        window.location.reload();
      }
    }
    forward(operation);
  }
);
const optiiPagination = {
  ...relayStylePagination(),
  keyArgs: ["filters", "projectCycleId"]
};
const paginatedQueries = [
  "GetDoneJobsList",
  "ListJobItems",
  "ListEmployees",
  "ListInvites",
  "ListLocations",
  "ListJobEscalationDefs",
  "assetTypes",
  "assets",
  "projects",
  "assetJobs",
  "projectCycleJobs",
  "repeatingJobSummaries",
  "projectSummaries",
  "checklistTemplates"
];

// Have to stop getting typenames, since we have many types with the same IDs, thanks sharding
const appCache = new InMemoryCache({
  // addTypename: false,
  typePolicies: {
    ProjectCycle: {
      keyFields: ["id", "projectCycleHash"] // virtual cycles don't possess an unique ID as physical cycles but cycleHash, this is needed in order to avoid that.
    },
    Query: {
      fields: paginatedQueries.reduce(
        (prev, item) => ({ ...prev, [item]: optiiPagination }),
        {}
      )
    }
  }
});

const GRAPH_SERVER = config.REACT_APP_GRAPH_SERVER;
const NODE_GRAPH_SERVER =
  config.REACT_APP_NODE_GRAPH_SERVER || "http://localhost:4000/graphql";

const goLink = createUploadLink({ uri: GRAPH_SERVER });
const nodeLink = createUploadLink({ uri: NODE_GRAPH_SERVER });

const instanceSelector = split(
  ({ getContext }) => {
    const context = getContext();
    return context._instance === "node";
  },
  nodeLink,
  goLink
);

// Should eventually move this endpoint into its own config variable
let wsLinkGo = new WebSocketLink({
  uri: GRAPH_SERVER.replace("http", "ws"),
  options: {
    reconnect: true,
    timeout: 500000
    //TODO: once graphql works correctly, this should be used
    // connectionParams: () => {

    // }
  }
});

const GraphClient = function(auth) {
  let activeSocket, timedOut;
  const wsLinkNode = new wsLink({
    url: NODE_GRAPH_SERVER.replace("http", "ws"),
    connectionParams: async () => {
      const localProperty = JSON.parse(localStorage.getItem("optiiProperty"));
      const sessionProperty = JSON.parse(
        sessionStorage.getItem("optiiProperty")
      );
      let propId;
      sessionProperty
        ? (propId = sessionProperty.id)
        : (propId = localProperty && localProperty.id);

      const token = await auth.getValidToken();
      return {
        authorization: token && `Bearer ${token.accessToken}`,
        "x-optii-userinfo": `{"shard":[${propId || ""}]}`,
        "x-optii-correlation-id": uuidv4()
      };
    },
    keepAlive: 120_000, // ping every 120 seconds
    retryAttempts: 10, // retry 10 times if disconnected/timed out
    shouldRetry: () => true,
    on: {
      connected: socket => (activeSocket = socket),
      ping: received => {
        if (!received)
          // sent
          timedOut = setTimeout(() => {
            if (activeSocket.readyState === WebSocket.OPEN)
              activeSocket.close(4408, "Request Timeout");
          }, 10_000); // wait 10 seconds for the pong and then close the connection
      },
      pong: received => {
        if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
      },
      closed: event => {
        console.log("WebSocket connection closed", event);
      }
    }
  });

  const wsLinkSelector = split(
    ({ query, getContext }) => {
      const definition = getMainDefinition(query);
      const context = getContext();
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription" &&
        context._instance === "node"
      );
    },
    wsLinkNode,
    wsLinkGo
  );

  // wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
  //   wsLink.subscriptionClient.maxConnectTimeGenerator.max;

  const link = split(
    ({ query, getContext }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLinkSelector,
    instanceSelector
  );
  //We support _skipAuth (and if it is present, _shard) in the context
  const newAuthMiddleware = setContext(async (request, previousContext) => {
    const { headers } = previousContext;
    try {
      const authHeaders = await getAuthHeaders(request, previousContext, auth);
      const newHeaders = {
        ...headers,
        "X-Optii-Correlation-Id": uuidv4(),
        ...authHeaders
      };

      return { headers: newHeaders };
    } catch (error) {
      console.log("GraphClient Error: ", error);
      throw error;
    }
  });

  const GraphClientInstance = new ApolloClient({
    link: newAuthMiddleware.concat(errorLink.concat(link)),
    cache: appCache,
    connectToDevTools: true,
    defaultOptions: {
      query: {
        //disabling cache by default for now
        fetchPolicy: "no-cache"
      },
      watchQuery: {
        fetchPolicy: "no-cache"
      }
    }
  });
  return GraphClientInstance;
};
export default GraphClient;
