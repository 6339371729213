import moment from "moment";
// All default formats in moment locale:
// longDateFormat : {
//   LT: "h:mm A", // overridden below
//   LTS: "h:mm:ss A", // overridden below
//   L: "MM/DD/YYYY",
//   l: "M/D/YYYY", // overridden below
//   LL: "MMMM Do YYYY", // overridden below
//   ll: "MMM D YYYY",
//   LLL: "MMMM Do YYYY LT",
//   lll: "MMM D YYYY LT", // overridden below
//   LLLL: "dddd, MMMM Do YYYY LT", // overridden below
//   llll: "ddd, MMM D YYYY LT"
// }

// override existed keys, not allowed to created own.
export const enDateFormats = {
  l: "MMM D",
  LL: "MMM DD YYYY",
  lll: "MMM DD",
  LLLL: "dddd, MMMM DD, YYYY"
};
export const asiaLangDateFormats = {
  l: "MMM D",
  LL: "YYYY MMM DD",
  lll: "MMM DD",
  LLLL: "dddd, MMMM DD, YYYY"
  // llll: "DD MMM, YYYY, LT"
};
export const otherLangDateFormats = {
  l: "D MMM",
  LL: "DD MMM YYYY",
  lll: "DD MMM",
  LLLL: "dddd, DD MMMM, YYYY"
  // llll: "DD MMM, YYYY, LT"
};

// for non-military time
export const nonMilitaryTimeFormats = {
  LT: "hh:mma", // LT: "hh:mma" - to set "hh:mma" format for English, because by default LT is "hh:mm A".
  LTS: "h:mm a" // LTS: "h:mm a" - used such key to handle "h:mm a" as LT already used. Not allowed to use own keys.
};
// for military time
export const militaryTimeFormats = {
  LT: "HH:mm", // LT: "hh:mma" - to set "hh:mma" format for English, because by default LT is "hh:mm A".
  LTS: "H:mm" // LTS: "h:mm a" - used such key to handle "h:mm a" as LT already used. Not allowed to use own keys.
};

// properties for Haitian Creole lang from another library https://github.com/iamkun/dayjs/blob/master/src/locale/ht.js
export const htLangProperties = {
  parentLocale: "fr",
  months: "janvye_fevriye_mas_avril_me_jen_jiyè_out_septanm_oktòb_novanm_desanm".split(
    "_"
  ),
  monthsShort: "jan._fev._mas_avr._me_jen_jiyè._out_sept._okt._nov._des.".split(
    "_"
  ),
  weekdays: "dimanch_lendi_madi_mèkredi_jedi_vandredi_samdi".split("_"),
  weekdaysShort: "dim._len._mad._mèk._jed._van._sam.".split("_"),
  weekdaysMin: "di_le_ma_mè_je_va_sa".split("_")
};

export const paLangProperties = {
  parentLocale: "pa-in",
  weekdays: "ਐਤਵਾਰ_ਸੋਮਵਾਰ_ਮੰਗਲਵਾਰ_ਬੁਧਵਾਰ_ਵੀਰਵਾਰ_ਸ਼ੁੱਕਰਵਾਰ_ਸ਼ਨੀਚਰਵਾਰ".split("_"),
  months: "ਜਨਵਰੀ_ਫ਼ਰਵਰੀ_ਮਾਰਚ_ਅਪ੍ਰੈਲ_ਮਈ_ਜੂਨ_ਜੁਲਾਈ_ਅਗਸਤ_ਸਤੰਬਰ_ਅਕਤੂਬਰ_ਨਵੰਬਰ_ਦਸੰਬਰ".split(
    "_"
  ),
  weekdaysShort: "ਐਤ_ਸੋਮ_ਮੰਗਲ_ਬੁਧ_ਵੀਰ_ਸ਼ੁਕਰ_ਸ਼ਨੀ".split("_"),
  monthsShort: "ਜਨਵਰੀ_ਫ਼ਰਵਰੀ_ਮਾਰਚ_ਅਪ੍ਰੈਲ_ਮਈ_ਜੂਨ_ਜੁਲਾਈ_ਅਗਸਤ_ਸਤੰਬਰ_ਅਕਤੂਬਰ_ਨਵੰਬਰ_ਦਸੰਬਰ".split(
    "_"
  ),
  weekdaysMin: "ਐਤ_ਸੋਮ_ਮੰਗਲ_ਬੁਧ_ਵੀਰ_ਸ਼ੁਕਰ_ਸ਼ਨੀ".split("_")
};

export const mnLangProperties = {
  name: "mn",
  weekdays: "Ням_Даваа_Мягмар_Лхагва_Пүрэв_Баасан_Бямба".split("_"),
  months: "Нэгдүгээр сар_Хоёрдугаар сар_Гуравдугаар сар_Дөрөвдүгээр сар_Тавдугаар сар_Зургадугаар сар_Долдугаар сар_Наймдугаар сар_Есдүгээр сар_Аравдугаар сар_Арван нэгдүгээр сар_Арван хоёрдугаар сар".split(
    "_"
  ),
  weekdaysShort: "Ням_Дав_Мяг_Лха_Пүр_Баа_Бям".split("_"),
  monthsShort: "1 сар_2 сар_3 сар_4 сар_5 сар_6 сар_7 сар_8 сар_9 сар_10 сар_11 сар_12 сар".split(
    "_"
  ),
  weekdaysMin: "Ня_Да_Мя_Лх_Пү_Ба_Бя".split("_")
};

export const use12Hours = () => {
  return moment
    .localeData()
    .longDateFormat("LT")
    .includes("a");
};

export const langFormats = {
  en: { ...enDateFormats, ...nonMilitaryTimeFormats },
  "en-au": { ...otherLangDateFormats, ...nonMilitaryTimeFormats },
  "en-nz": { ...otherLangDateFormats, ...nonMilitaryTimeFormats },
  el: { ...otherLangDateFormats, ...militaryTimeFormats },
  "tl-ph": { ...enDateFormats, ...militaryTimeFormats },
  "zh-CN": { ...asiaLangDateFormats, ...militaryTimeFormats },
  "zh-TW": { ...asiaLangDateFormats, ...militaryTimeFormats },
  ja: { ...asiaLangDateFormats, ...militaryTimeFormats },
  other: { ...otherLangDateFormats, ...militaryTimeFormats }
};
