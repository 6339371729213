import colors from "../colors";
import shadows from "../shadows";
import borders from "../borders";
import breakpoints from "../breakpoints";
export default `
background: ${colors.white};
${shadows.default}
${borders.shadow}
border-radius: 8px;
margin: auto;
margin-bottom: 1rem;
padding: 2rem;
position: relative;
@media (max-width: ${breakpoints.sm}) {
  border: none;
  box-shadow: none;
}
`;
