import { gql } from "@apollo/client";

export default gql`
  query RepeatingJobOccurrenceSummary($id: ID!) {
    repeatingJobOccurrenceSummary(id: $id) {
      repeatingJobId
      lastOccurrenceDate
      nextOccurrenceDate
    }
  }
`;
