import { gql } from "@apollo/client";

export default gql`
  subscription JobUpdated(
    $XOptiiUserInfo: String!
    $authToken: String!
    $filters: Map
  ) {
    JobUpdated(
      XOptiiUserInfo: $XOptiiUserInfo
      authToken: $authToken
      filters: $filters
    ) {
      id
      item
      action
      priority
      status
      doBy
      completedAt
      locations {
        id
        name
        type
        number
      }
      notes {
        createdBy {
          id
          firstName
          lastName
          userName
        }
        time
        text
      }
      assignee {
        firstName
        lastName
        id
      }
      reporter {
        id
        firstName
        lastName
        userName
      }
      role {
        id
        name
      }
      department {
        id
        displayName
        departmentCode
      }
      periods {
        jobId
        periodStart
        periodEnd
        jobStatus
        assigneeId
        assignee {
          id
          userName
          firstName
          lastName
        }
      }
      metadata {
        externalId
        source
        publicAttributes
        privateAttributes
      }
      attachments
      createdAt
      startedAt
      completedAt
    }
  }
`;
