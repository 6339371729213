import i18n from "i18n";
import permissions from "./permissions";
import languages from "./languages";
import countries from "./countries";
import timeZones from "./timeZones";
import scheduleColors from "./scheduleColors";
import durationOptions from "./durationOptions";
import months from "./months";
import daysOfWeek from "./daysOfWeek";
import jobStatus from "./jobStatus";
import teamMemberJobStatus from "./teamMemberJobStatus";
import productAccess from "./productAccess";
import gaEvents from "./gaEvents";
import importLocationStatus from "./importLocationStatus.js";
import locationStatuses from "./locationStatuses.js";
import teamMemberSections from "./teamMemberSections";
import deleteAssigneeOption from "./deleteAssigneeOption";
import jobsView from "./jobsView";

var translatedConstants = {};
export default translatedConstants = {};

export const ADMINS = ["admin"];

export const PAYMENT_OPTIONS = {
  hourly: "hourly",
  salary: "salary",
  perRoom: "per_room"
};

export const getProductList = () => ({
  configuration: i18n.t("common:Configuration"),
  communication: i18n.t("common:Chat"),
  house_keeping: i18n.t("common:Housekeeping"),
  service_delivery: i18n.t("common:Service delivery"),
  schedule: i18n.t("common:Schedule"),
  user_profile: i18n.t("common:User profile"),
  jobs: i18n.t("common:Service Delivery"),
  reports: i18n.t("common:Reports"),
  home_dashboard: i18n.t("common:Home dashboard"),
  settings: i18n.t("common:Settings"),
  floorplan: i18n.t("common:Floorplan"),
  engineering: i18n.t("common:Projects")
});

export const PERMISSIONS = permissions;

export const MAX_EMPLOYEE_HOURS = 40;

export const DEFAULT_EMPLOYEE_ROLE_STATUS = "active";

export const SCHEDULE_STATES = {
  published: "published",
  draft: "draft"
};

export const PASSWORD_RULES = {
  minLength: 4,
  mustHaveLower: true
};

export const GENERAL_CHANNEL = "ALL";

export const LANGUAGES = languages;
export const COUNTRIES = countries;
export const TIMEZONES = timeZones;
export const SCHEDULE_COLORS = scheduleColors;
export const DURATION_OPTIONS = durationOptions;
export const MONTHS = months;
export const DAYS_OF_WEEK = daysOfWeek;
export const JOB_STATUS = jobStatus;
export const AUTO_ASSIGN_ID = "-88";
export const JOBS_VIEW = jobsView;
export const TEAM_MEMBER_JOB_STATUS = teamMemberJobStatus;
export const PRODUCT_ACCESS = productAccess;

export const PERMISSION_NAME = "name";

// floor plan
export const IMPORT_STATUS = importLocationStatus;
export const LOCATIONS_LIMIT = 10;
export const LOCATION_FIELDS = {
  roomType: "roomType",
  name: "name"
};
export const LOCATION_TYPES_NAMES = {
  room: "Room",
  property: "Property"
};
export const LOCATION_TYPE_NOT_SORTABLE_FIELDS = ["note"];
export const TYPE_FIELDS = {
  displayName: "displayName",
  code: "code"
};
const getTypesTableName = _ => ({
  locationTypes: i18n.t("floorplan:Location Types"),
  roomTypes: i18n.t("floorplan:Room Types")
});

export const GA_EVENTS = gaEvents;
export const LOCATION_STATUSES = locationStatuses;

// Auth related
export const AUTH_ACCEPT_REFER = "acceptInviteReferPath";
export const AUTH_INVITE_ID = "inviteId";
export const AUTH_SI_ERR = "signInAuthError";
export const AUTH_ERROR_USER_ID = "noUserId";
export const AUTH_ERROR_ACCESS = "noAccess";
export const AUTH_ERRORS_MAP = {
  general:
    "An error occurred authenticating user, please contact your administrator",
  [AUTH_ERROR_USER_ID]: "No valid user ID found on the auth token",
  [AUTH_ERROR_ACCESS]:
    "You are no longer an active user.  If this is an error, please contact your manager."
};

export const DEFAULT_PAGE_SIZE = 50;

export const JOB_ITEMS_NOT_SORTABLE_FIELDS = [
  "itemName",
  "jobActions",
  "departments",
  "roles"
];

export const ESCALATIONS_NOT_SORTABLE_FIELDS = [
  "roles",
  "source",
  "priorities"
];
export const LIST_FETCH_POLICIES = {
  fetchPolicy: "cache-and-network", // works for Departments, Users, and brokes (if we want to delete one and alone item on the page) JobItems, Floorplan -  does not update cache on refetch (https://github.com/apollographql/apollo-client/issues/6804). Removing this policy brokes pagination. Temporary solution is to use not refetch but get api call function.
  nextFetchPolicy: "cache-first"
};
export const LEARNING_CENTER_URL =
  "https://help.optiisolutions.com/?__hstc=151591270.34456e4050144d8c4378ad0eaeb54ec5.1609813504095.1609967388824.1610145395136.3&__hssc=151591270.1.1610145395136&__hsfp=2116342209";
export const LEARNING_VIDEO_URL =
  "https://help.optiisolutions.com/getting-started-with-optii-service-chat";

export const ASSET_VIEWS = {
  assetType: "assetType",
  asset: "asset"
};

export const TEAM_MEMBER_SECTIONS = teamMemberSections;

export const DELETE_ASSIGNEE_OPTION = deleteAssigneeOption;

function updateTranslations() {
  translatedConstants.typesTableName = getTypesTableName();
  translatedConstants.productList = getProductList();
}

// i18next seems ready -> initial fill translations
if (i18n.isInitialized) {
  updateTranslations();
}

// reset translations to new values on language change
i18n.on("languageChanged", function(lng) {
  updateTranslations();
});

// we loaded some translation file? reset needed?!?
// https://www.i18next.com/overview/api#store-events
i18n.on("loaded", function(lng) {
  updateTranslations();
});
