import React from "react";
import { Redirect } from "react-router-dom";
import useAccess from "utils/useAccess";
import { useHistory, Route } from "react-router-dom";
import { Auth } from "components/Main/Authentication/AuthService";

const OptiiRoute = props => {
  const { can } = useAccess();

  const history = useHistory();

  if (!Auth.Instance.session.isAuthenticated) {
    setTimeout(_ => {
      if (!Auth.Instance.session.isAuthenticated) {
        const fromUri = history.location.pathname + history.location.search;
        if (!fromUri.match(/login/)) {
          Auth.Instance.setFromUri(fromUri);
        }
        history.push("/login");
      }
    }, 0);

    return null;
  }

  //If they don't have the proper permissions, send them to the dashboard
  return can(
    props.permissions,
    <Route {...props} />,
    <Route render={_ => <Redirect to="/dashboard" />} />
  );
};

export default OptiiRoute;
