import { gql } from "@apollo/client";

export default gql`
  query GetProjectsList(
    $sorts: String
    $filters: String
    $first: Int
    $after: String
  ) {
    page: projects(
      sorts: $sorts
      filters: $filters
      first: $first
      after: $after
    ) {
      totalCount
      edges {
        node {
          ... on Project {
            id
            displayName
            description
            status
            serviceCycleId
            projectStartDate
            projectEndDate
            isDeleted
            serviceCycle {
              type
              value
              displayName
            }
            currentCycle {
              id
              startDate
              endDate
            }
            nextCycle {
              startDate
              endDate
            }
            projectCycles {
              percentComplete
              percentTimeComplete
              startDate
              endDate
              displayStartDate
              displayEndDate
              projectCycleStatus
              displayName
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
