import orderBy from "lodash/orderBy";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range.min";
import i18n from "i18n";

import { formatUserDisplayName } from "utils/formatters/user";
import { getOffset, getDuration } from "utils/formatters/escalations";
import { JOB_STATUS } from "utils/constants";

const _getStatuses = _ => ({
  queued: { id: "queued", displayName: i18n.t("jobs:Queued") },
  pending: { id: "pending", displayName: i18n.t("jobs:Not Started") },
  in_progress: { id: "in_progress", displayName: i18n.t("jobs:In Progress") },
  completed: { id: "completed", displayName: i18n.t("common:Done") },
  cancelled: { id: "cancelled", displayName: i18n.t("jobs:Cancelled") },
  on_hold: { id: "on_hold", displayName: i18n.t("jobs:On Hold") }
});

const _getJobStatusOptions = _ => ({
  not_started: { id: "not_started", displayName: i18n.t("jobs:Not Started") },
  in_progress: { id: "in_progress", displayName: i18n.t("jobs:In Progress") },
  completed: { id: "completed", displayName: i18n.t("common:Done") },
  cancelled: { id: "cancelled", displayName: i18n.t("jobs:Cancelled") },
  on_hold: { id: "on_hold", displayName: i18n.t("jobs:On Hold") }
});

const _getAdvancedStatuses = _ => ({
  new: { id: "new", displayName: i18n.t("jobs:Not Started") },
  archived: { id: "archived", displayName: i18n.t("common:Done") }
});

const _getPriorities = _ => ({
  highest: { id: "highest", displayName: i18n.t("jobs:Highest") },
  high: { id: "high", displayName: i18n.t("jobs:High") },
  medium: { id: "medium", displayName: i18n.t("jobs:Medium") },
  low: { id: "low", displayName: i18n.t("jobs:Low") }
});

const _getActions = _ => ({
  clean: { id: "clean", displayName: i18n.t("jobs:Clean") },
  deliver: { id: "deliver", displayName: i18n.t("jobs:Deliver") },
  remove: { id: "remove", displayName: i18n.t("jobs:Remove") },
  repair: { id: "repair", displayName: i18n.t("jobs:Repair") },
  replace: { id: "replace", displayName: i18n.t("jobs:Replace") },
  inspect: { id: "inspect", displayName: i18n.t("jobs:Inspect") },
  install: { id: "install", displayName: i18n.t("jobs:Install") },
  perform: { id: "perform", displayName: i18n.t("jobs:Perform") },
  test: { id: "test", displayName: i18n.t("jobs:Test") }
});

const _getJobTypes = _ => ({
  internal: i18n.t("jobs:Internal Request"),
  guest: i18n.t("jobs:Guest Request")
});

const _getAuditActions = _ => ({
  edited: i18n.t("jobs:Updated by"),
  taken: i18n.t("jobs:Taken by"),
  assigned: i18n.t("jobs:Assigned by"),
  started: i18n.t("jobs:Started by"),
  paused: i18n.t("jobs:Paused by"),
  completed: i18n.t("jobs:Completed by"),
  cancelled: i18n.t("jobs:Cancelled by"),
  archived: i18n.t("jobs:Deleted by"),
  created: i18n.t("common:Added by"),
  added: i18n.t("common:Added by"),
  reopened: i18n.t("jobs:Reopened by"),
  assignedTo: i18n.t("jobs:Assigned to"),
  disabled: i18n.t("jobs:Could Not Assign"),
  escalated: i18n.t("jobs:Escalated to")
});

const _auditActions = {
  edited: "Updated by",
  taken: "Taken by",
  assigned: "Assigned by",
  started: "Started by",
  paused: "Paused by",
  completed: "Completed by",
  cancelled: "Cancelled by",
  archived: "Deleted by",
  created: "Added by",
  added: "Added by",
  reopened: "Reopened by",
  assignedTo: "Assigned to",
  disabled: "Could Not Assign",
  escalated: "Escalated to"
};

const _getCommonTranslations = _ => ({
  by: i18n.t("common:by")
});

export const translatedData = {};

function updateTranslations() {
  translatedData._priorities = _getPriorities();
  translatedData.priorities = Object.values(_getPriorities());
  translatedData._actions = _getActions();
  translatedData.actions = Object.values(_getActions());
  translatedData._statuses = _getStatuses();
  translatedData.jobStatusOptions = Object.values(_getJobStatusOptions());
  translatedData._advancedStatuses = _getAdvancedStatuses();
  translatedData.statuses = Object.values(_getStatuses());
  translatedData.jobTypes = _getJobTypes();
  translatedData._auditActions = _getAuditActions();
  translatedData.common = _getCommonTranslations();
}

// i18next seems ready -> initial fill translations
if (i18n.isInitialized) {
  updateTranslations();
}

// reset translations to new values on language change
i18n.on("languageChanged", function(lng) {
  updateTranslations();
});

// we loaded some translation file? reset needed?!?
// https://www.i18next.com/overview/api#store-events
i18n.on("loaded", function(lng) {
  updateTranslations();
});

export function getType(job) {
  return translatedData.jobTypes[job.type];
}

export const statuses = translatedData.statuses;
export const addJobStatusOptions = translatedData._getAddJobStatuses;
export function getStatus(job) {
  const statusMap = {
    ...translatedData._statuses,
    ...translatedData._advancedStatuses
  };
  return statusMap[job.status];
}

export const priorities = translatedData.priorities;
export function getPriority(job) {
  return translatedData._priorities[job.priority];
}

const priorityLevel = { highest: 0, high: 1, medium: 2, low: 3 };

export function sortByPriorityAndDoBy(jobs) {
  return orderBy(jobs, [job => [priorityLevel[job.priority]], "doBy"]);
}

const _actions = {
  clean: { id: "clean", displayName: "Clean" },
  deliver: { id: "deliver", displayName: "Deliver" },
  remove: { id: "remove", displayName: "Remove" },
  repair: { id: "repair", displayName: "Repair" },
  replace: { id: "replace", displayName: "Replace" },
  inspect: { id: "inspect", displayName: "Inspect" },
  install: { id: "install", displayName: "Install" },
  perform: { id: "perform", displayName: "Perform" },
  test: { id: "test", displayName: "Test" }
};

export const actions = Object.values(_actions);

export function getAction(action, isTranslated = false) {
  if (!action) return "";
  const actions = isTranslated ? translatedData._actions : _actions;
  return actions[action?.toLowerCase()];
}

export function getName(job, isTranslated = false) {
  const showAction = !job.parentChecklistTaskId;
  return {
    short: `#${job.id}`,
    medium: `${getPriority(job).displayName} | ${getLocations(
      job,
      isTranslated
    )} : ${
      getAction(job.action) && showAction
        ? getAction(job.action).displayName
        : ""
    } ${job.item}`,
    long: `#${job.id} ${
      getAction(job.action) && showAction
        ? getAction(job.action).displayName
        : ""
    } ${job.item}`,
    longer: `${getLocations(job, isTranslated)} : ${
      getAction(job.action) && showAction
        ? getAction(job.action).displayName
        : ""
    } ${job.item}`,
    longest: `#${job.id} | ${getLocations(job, isTranslated)} : ${
      getAction(job.action) && showAction
        ? getAction(job.action).displayName
        : ""
    } ${job.item}`,
    longestWithoutId: `${getLocations(job, isTranslated)} : ${
      getAction(job.action) && showAction
        ? getAction(job.action).displayName
        : ""
    } ${job.item}`
  };
}

export function getLocations(job) {
  const locations = job.locations
    ? job.locations.map(loc => loc.shortDisplayName).join(", ")
    : job.location &&
      JSON.parse(job.location)
        .map(loc => loc.shortDisplayName)
        .join(", "); // Need this tertiary operation since push notifications use "location" not "locations"
  return locations;
}

export function getDescription(job, isTranslated = false) {
  const showAction = !job.parentChecklistTaskId;
  const actions = isTranslated ? translatedData._actions : _actions;
  return `${getLocations(job)}: ${
    actions[job.action?.toLowerCase()] && showAction
      ? actions[job.action?.toLowerCase()].displayName
      : ""
  } ${job.item}`;
}

export function getTeamMember(assignee, t) {
  if (!assignee) return "";

  if (assignee.id === "-88")
    return {
      short: t("jobs:Auto A"),
      long: t("jobs:Auto Assign")
    };

  return {
    short: `${assignee.firstName} ${assignee.lastName[0]}`,
    long: `${assignee.firstName} ${assignee.lastName}`
  };
}

function namesAreTheSame(audit) {
  if (!audit.details) {
    return false;
  }
  return formatUserDisplayName(audit.employee) === audit.details;
}

export function getAuditAction(audit, t = false) {
  if (audit.action === "taken") {
    return formatTakenAuditAction(audit, t);
  }
  if (audit.action === "escalated") {
    return formatEscalationAuditAction(audit, t);
  }
  if (audit.action === "converted")
    return i18n.t("jobs:Converted {{task}} to Job {{id}}", {
      task: audit.modifier1,
      id: audit.details
    });
  return formatDefaultAuditAction(audit, t);
}

function getFormatedModifier(modifier1) {
  if (modifier1 === "ERROR") {
    return i18n.t("jobs:Task Fail");
  } else if (modifier1 === "") {
    return i18n.t("jobs:Task Pass");
  }
  return modifier1;
}

function formatDefaultAuditAction(audit, isTranslated) {
  const auditActions = isTranslated
    ? translatedData._auditActions
    : _auditActions;

  return `${
    audit.details
      ? audit.details
          .split(" ")
          .map(
            detail => `${detail[0]?.toUpperCase() ?? ""}${detail?.substring(1)}`
          )
          .join(" ")
      : ""
  } ${
    typeof audit.modifier1 === "string"
      ? getFormatedModifier(audit.modifier1)
      : auditActions[audit.action] || auditActions["edited"]
  } ${audit.object === "ChecklistTask" ? " - " : ""} ${
    audit.action === "disabled" ? "" : formatUserDisplayName(audit.employee)
  }`;
}

function formatTakenAuditAction(audit, isTranslated) {
  const auditActions = isTranslated
    ? translatedData._auditActions
    : _auditActions;
  if (namesAreTheSame(audit)) {
    return `${auditActions.taken} ${formatUserDisplayName(audit.employee)}`;
  } else {
    return `${auditActions.assignedTo} ${audit.details} ${
      isTranslated ? translatedData.common.by : "by"
    } ${formatUserDisplayName(audit.employee)}`;
  }
}

function formatEscalationAuditAction(audit, t) {
  const auditActions = t ? translatedData._auditActions : _auditActions;
  let offSetType = audit.details;
  let timeOffset = audit.modifier1;
  let assignedType = audit.modifier2;
  let role = audit.object.split(",").join(", ");
  let filler = t("common:since job is");

  if (assignedType === null) {
    return `${auditActions.escalated} ${capitalizeEachWord(
      role
    )} ${filler} ${getDuration(timeOffset)} ${getOffset(offSetType)}`;
  }
  return `${auditActions.escalated} ${capitalizeEachWord(
    role
  )} ${filler} ${assignedType} ${t("common:and is")} ${getDuration(
    timeOffset
  )} ${getOffset(offSetType)}`;
}

export const capitalizeEachWord = sentence =>
  sentence.replace(/\b(\w)/g, letter => letter.toUpperCase());

export function generateOpenJobUrl(jobId, propertyId) {
  return `${window.location.origin}/jobs?openJob=${jobId}&propertyId=${propertyId}`;
}

export function getJobAssignedPushNotificationTitle(data, t) {
  return `${t("common:You have a new job")} ${getDescription(data)}`;
}

export function getJobAssignedPushNotificationBody(data, t) {
  return t(`common:Due by {{dateTime}}`, {
    dateTime: moment
      .unix(data.dueBy)
      .tz(data.timeZone)
      .format("l [at] LTS")
  });
}

// used for job card
export function getLatestNote(job) {
  const notes = job.notes;
  if (!notes || notes.length < 1) return "";
  return [...notes].sort(function(a, b) {
    return b.time - a.time;
  })[0].text;
}
export function getDueTime(doBy) {
  return doBy && doBy.format("l LT");
}
export function isDone(job) {
  return [JOB_STATUS.completed, JOB_STATUS.cancelled].includes(job.status);
}
export function isOverdue(job) {
  const doBy = job.doBy;
  const now = moment().unix();
  if (isDone(job)) return false;
  return doBy < now;
}
export const getTooltipPosition = (job, index) => {
  if (index === 0) {
    if (job.status === JOB_STATUS.on_hold) {
      return "bottom right";
    }
    return "bottom left";
  }
  if (job.status === JOB_STATUS.on_hold) {
    return "top right";
  }
  return "top left";
};
