import { gql } from "@apollo/client";

export default gql`
  query Asset($assetId: ID!) {
    asset(id: $assetId) {
      id
      displayName
      status
      notes
      documents {
        uuid
      }
      location {
        displayName
        id
      }
      assetType {
        displayName
        id
        jobItem {
          id
          displayName
          isDeleted
        }
      }
      version
    }
  }
`;
