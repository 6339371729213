import styled from "styled-components/macro";
import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import useScrollLock from "utils/useScrollLock";
import Backdrop from "components/shared/Backdrop";
import { applyStyleModifiers } from "styled-components-modifiers";
import ReactGA from "utils/analytics/GoogleAnalyticsClient";

const MODIFIER_CONFIG = {
  right: () => `
    left: auto;
    right: 0;
    transform: translate(0, -50%);
    border-radius: 0;
    height: 100%;
    overflow-y: auto;
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  `,
  locationTypes: () => `
    p {
      margin: 0;
    }
  `,
  childrenPadding: () => `
    padding: 0;
  `,
  small: () => `
    max-width: 31.2rem;
    padding: 2.4rem;
  `,
  flex: () => `
    display: flex;
    flex-direction: column;
  `
};
let modalRoot = document.getElementById("modal-root");
// for tests, where there is no root
if (!modalRoot) {
  modalRoot = document.createElement("div");
  modalRoot.setAttribute("id", "modal-root");
  document.body.appendChild(modalRoot);
}
const Pane = styled.div`
  ${props => props.theme.elements.card}
  ${props =>
    props.superModal
      ? props.theme.zIndex.superModal
      : props.zIndex || props.theme.zIndex.modal}
  p {
    ${props => props.theme.fonts.weights.light}
    margin: 2rem 0;
  }
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1.3rem 2rem 3rem;
  width: 48rem;
  background-color: white;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;

const Modal = props => {
  const { modalName, forceFocus, subModal } = props;
  const modalEl = useRef(null);

  useEffect(
    _ => {
      if (modalName) {
        ReactGA.modalview(modalName);
        return _ => ReactGA.pageview(window.location.pathname);
      }
    },
    [modalName]
  );

  useEffect(
    _ => {
      if (forceFocus) {
        modalEl.current.focus();
      }
    },
    [forceFocus]
  );

  useScrollLock(modalEl.current);
  const localHandleClickBackdrop = e => {
    e.stopPropagation();
  };
  const handleClick = props.handleClickBackdrop || localHandleClickBackdrop;

  return ReactDOM.createPortal(
    <div>
      <Pane
        className="modal"
        data-testid={modalName || "modal"}
        {...props}
        ref={modalEl}
        tabIndex="0"
        superModal={subModal}
      >
        {props.children}
      </Pane>
      <Backdrop
        solid={props.solidBack}
        superModal={subModal}
        onClick={handleClick}
      />
    </div>,
    subModal ? document.getElementById("sub-modal") : modalRoot
  );
};
export default Modal;
