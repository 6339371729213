import { gql } from "@apollo/client";
export default gql`
  mutation Mutation(
    $projectId: ID!
    $projectCycleHash: String!
    $frequencyHash: String!
    $updateVirtualProjectCycleInput: UpdateVirtualProjectCycleInput!
  ) {
    updateVirtualProjectCycle(
      projectId: $projectId
      projectCycleHash: $projectCycleHash
      frequencyHash: $frequencyHash
      updateVirtualProjectCycleInput: $updateVirtualProjectCycleInput
    ) {
      id
      projectCycleHash
    }
  }
`;
