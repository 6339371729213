import { gql } from "@apollo/client";

export default gql`
  mutation BulkAssignVirtualProjectCycleJobs(
    $projectId: ID!
    $projectCycleHash: String!
    $frequencyHash: String!
    $bulkAssignVirtualCycleJobsInput: [AssignProjectCycleJobInput]!
  ) {
    bulkAssignVirtualCycleJobs(
      projectId: $projectId
      projectCycleHash: $projectCycleHash
      frequencyHash: $frequencyHash
      bulkAssignVirtualCycleJobsInput: $bulkAssignVirtualCycleJobsInput
    )
  }
`;
