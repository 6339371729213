import React, { useState } from "react";

const Context = React.createContext({});
const Provider = function(props) {
  const [onlineUsers, setOnlineUsers] = useState({
    employeeID: null,
    loggedIn: false,
    working: false,
    online: false
  });
  return (
    <Context.Provider value={{ onlineUsers, setOnlineUsers }}>
      <>{props.children}</>
    </Context.Provider>
  );
};

export default { Provider, Context };
