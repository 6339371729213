import { gql } from "@apollo/client";
export default gql`
  mutation updateJobById($id: ID!, $input: JobEdit!) {
    updateJobById(id: $id, input: $input) {
      id
      action
      item
      doBy
      status
      parentChecklistTaskId
    }
  }
`;
