import { gql } from "@apollo/client";
export default gql`
  mutation updateJobMetadata(
      $id: ID!,
      $schema: String!,
      $input: MetadataEdit!,
    ) {
    updateJobMetadata(id: $id, schema: $schema, input: $input) {
      id
      status
    }
  }
`;
