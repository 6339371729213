import { useEffect } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// I hate this, taken from body-scroll-lock
const isIosDevice =
  typeof window !== "undefined" &&
  window.navigator &&
  window.navigator.platform &&
  (/iP(ad|hone|od)/.test(window.navigator.platform) ||
    (window.navigator.platform === "MacIntel" &&
      window.navigator.maxTouchPoints > 1));
// Used for disabling scroll on body while still allowing it on a specific element
// Main use case is modal
export default function useValidatePassword(element) {
  useEffect(() => {
    if (!isIosDevice) {
      disableBodyScroll(element);
      return function() {
        enableBodyScroll(element);
      };
    }
  });
}
