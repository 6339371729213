import React from "react";
import Confirmation from "components/shared/Confirmation";
import ConfirmButton from "components/shared/buttons/PrimaryButton";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

const Code = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  color: ${props => props.theme.colors.teal};
  ${props => props.theme.fonts.type.secondary};
  ${props => props.theme.fonts.sizes.gigantic};
  ${props => props.theme.fonts.weights.weak};
`;

function TableConfirmation(props) {
  const { t } = useTranslation(["common", "home"]);
  return (
    <Confirmation
      disabled={props.disabled}
      action={t("common:Continue")}
      title={props.title}
      hide={props.hide}
      confirm={props.confirm}
      button={ConfirmButton}
      cancel={true} //it hides default cancel btn
    >
      <p>
        {t("home:Congrats")}, {props.firstName},{" "}
        {t("home:you have been added to")} {props.orgName}.{" "}
        {t(
          "home:If you have any questions do not hesitate to reach out to us for assistance at"
        )}{" "}
        <a href="mailto:help@optiisolutions.com">help@optiisolutions.com</a>.
      </p>

      <Code>{props.code}</Code>
    </Confirmation>
  );
}
export default TableConfirmation;
