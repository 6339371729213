import { gql } from "@apollo/client";
export const fragment = `GetUserAccess(id: $id) {
  UserName
  Access {
    Properties {
      Name
      id
      Roles {
        id
        Permissions
      }
    }
  }
}`;
export default gql`
  query GetUserAccess($id: ID!) {
    access: ${fragment}
  }
`;
