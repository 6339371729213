import { gql } from "@apollo/client";
export default gql`
  mutation inviteUser($input: InviteUser!) {
    inviteUser(input: $input) {
      id
      code
      userFirstName
      userLastName
      status
      userId
      emailAddr
      mobileNum
    }
  }
`;
