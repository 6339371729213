import { gql } from "@apollo/client";

export default gql`
  query RepeatingJob($repeatingJobId: ID!) {
    job: repeatingJob(id: $repeatingJobId) {
      id
      version
      status
      displayName
      startDate
      endDate
      isDeleted
      frequencyHash
      frequency
      cycle {
        displayName
        type
        value
        frequency
      }
      jobTemplate {
        status
        type
        item
        action
        priority
        isEscalated
        dueByTime
        checklists {
          id
        }
        notes {
          id
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
          text
        }
        attachments
        reporter {
          id
          userName
          firstName
          lastName
        }
        assignee {
          id
          firstName
          lastName
        }
        department {
          id
          displayName
          departmentCode
        }
        locations {
          id
          name
          type
          number
          value: id
          title: name
        }
        role {
          id
          name
        }
        metadata {
          source
          publicAttributes {
            asset {
              id
            }
          }
        }
      }
    }
  }
`;
