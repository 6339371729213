import { gql } from "@apollo/client";

export default gql`
  query ListJobItems(
    $cursor: String
    $first: Int
    $orderBy: String
    $filters: Map
  ) {
    page: ListJobItems(
      filters: $filters
      first: $first
      after: $cursor
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        node {
          ... on JobItem {
            id
            displayName
            notes
            assignments {
              action
              department {
                id
                displayName
                departmentCode
              }
              role {
                id
                name
                description
              }
            }
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
