import { gql } from "@apollo/client";

export default gql`
  query GetDoneJobsList(
    $cursor: String
    $first: Int
    $orderBy: String
    $filters: Map
  ) {
    page: GetDoneJobsList(
      filters: $filters
      first: $first
      after: $cursor
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        node {
          ... on Job {
            id
            type
            item
            action
            priority
            isEscalated
            status
            completedAt
            doBy
            attachments
            parentChecklistTaskId
            locations {
              name
              shortDisplayName: name
              displayName
            }
            notes {
              createdBy {
                id
                firstName
                lastName
                userName
              }
              time
              text
            }
            assignee {
              firstName
              lastName
              id
            }
            reporter {
              id
              firstName
              lastName
              userName
            }
            department {
              displayName
              departmentCode
            }
            metadata {
              externalId
              source
              publicAttributes
              privateAttributes
            }
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
