import { gql } from "@apollo/client";

export default gql`
  query Brands($cursor: String, $first: Int, $sorts: String, $filters: String) {
    page: brands(
      first: $first
      sorts: $sorts
      filters: $filters
      after: $cursor
    ) {
      edges {
        node {
          ... on Brand {
            id
            displayName
          }
        }
      }
    }
  }
`;
