import { Requestor } from "@openid/appauth";
import { XhrSettings } from "ionic-appauth/lib/cordova";
import "@capacitor-community/http";

import { Plugins } from "@capacitor/core";
//Used by ionic-appauth to make requests
export class AjaxRequestor extends Requestor {
  public async xhr<T>(settings: XhrSettings) {
    const { Http } = Plugins;
    let url = settings.url;
    if (
      settings.data &&
      settings.headers["Content-Type"] &&
      settings.headers["Content-Type"] === "application/x-www-form-urlencoded"
    ) {
      // Strange stuff, you know you love it
      url = `${settings.url}?${settings.data}`;
    }

    if (!settings.method) settings.method = "GET";
    return Http.request({
      method: settings.method,
      url: url,
      headers: settings.headers,
      data: settings.data
    }).then(function(response: any) {
      return response.data;
    });
  }
}
