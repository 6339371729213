export default t => [
  { id: 10, displayName: `10 ${t("fields:Minutes")}` },
  { id: 15, displayName: `15 ${t("fields:Minutes")}` },
  { id: 20, displayName: `20 ${t("fields:Minutes")}` },
  { id: 25, displayName: `25 ${t("fields:Minutes")}` },
  { id: 30, displayName: `30 ${t("fields:Minutes")}` },
  { id: 35, displayName: `35 ${t("fields:Minutes")}` },
  { id: 40, displayName: `40 ${t("fields:Minutes")}` },
  { id: 45, displayName: `45 ${t("fields:Minutes")}` },
  { id: 50, displayName: `50 ${t("fields:Minutes")}` },
  { id: 55, displayName: `55 ${t("fields:Minutes")}` },
  { id: 60, displayName: `60 ${t("fields:Minutes")}` }
];
