import { gql } from "@apollo/client";

export default gql`
  query ParentAndTypeLocations(
    $name_LIKE: String
    $orderBy: String
    $onlyParents: Boolean
  ) {
    GetAllLocationTypes(filters: { name_LIKE: $name_LIKE }) {
      id
      displayName
    }
    ListLocations(
      filters: { locationName_LIKE: $name_LIKE, onlyParents: $onlyParents }
      orderBy: $orderBy
    ) {
      edges {
        node {
          ... on Location {
            id
            displayName
            childrenIds
          }
        }
      }
    }
  }
`;
