import React, { useState, useEffect, useCallback, useContext } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import isEqual from "lodash/isEqual";
import UserAccessContext from "contexts/UserAccessContext";
import Loading from "components/shared/Loading";
const Context = React.createContext({});
function getPropertyFromAccess(propertyId, access) {
  const currentProperty = access.Access.Properties.map(item => ({
    ...item,
    displayName: item.Name
  })).find(item => item.id === propertyId);
  // If the user no longer has access to the stored property, need to reset it
  if (propertyId && !currentProperty) {
    return false;
  } else {
    return currentProperty;
  }
}
const Provider = function(props) {
  const { user, access } = useContext(UserAccessContext.Context);
  const [loaded, setLoaded] = useState(false);
  const [propertyId, setPropertyId] = useQueryParam("propertyId", StringParam);
  const [property, _setProperty] = useState(false);
  const [loading, setLoading] = useState(false);

  // Handle updates to property
  // Accepts options:
  //   forceReload: will show loading indicator and reload page
  const setProperty = useCallback(
    (nextProperty, { forceReload, skipStorage } = {}) => {
      forceReload && setLoading(true);
      if (!user && nextProperty) {
        console.error("attempted to set a property without knowing the user!");
        throw new Error("Ouch!"); //TODO: remove me
      }
      const newProperty = { ...nextProperty, userId: user && user.id };
      // If we are unsetting, remove from storage
      if (!nextProperty) {
        !skipStorage && localStorage.removeItem("optiiProperty");
        !skipStorage && sessionStorage.removeItem("optiiProperty");
        _setProperty(nextProperty);
      } else if (!isEqual(newProperty, property)) {
        localStorage.setItem("optiiProperty", JSON.stringify(newProperty));
        sessionStorage.setItem("optiiProperty", JSON.stringify(newProperty));
        _setProperty(newProperty);
      }
      if (propertyId) {
        setPropertyId("");
      }
      forceReload && window.location.reload();
      setTimeout(_ => {
        setLoaded(true);
      }, 0);
      sessionStorage.setItem("showStartWorkingConfirmation", true);
    },
    [property, user]
  );

  useEffect(
    _ => {
      if (property) {
        sessionStorage.setItem("optiiProperty", JSON.stringify(property));
      }
    },
    [property]
  );

  // Set property from users access
  useEffect(
    _ => {
      function setPropertyFromStorage() {
        let storedProperty;
        var currentProperty =
          localStorage.getItem("optiiProperty") &&
          JSON.parse(localStorage.getItem("optiiProperty"));

        var sessionProperty =
          sessionStorage.getItem("optiiProperty") &&
          JSON.parse(sessionStorage.getItem("optiiProperty"));

        if (currentProperty) {
          if (sessionProperty) {
            storedProperty = sessionProperty;
          } else {
            storedProperty = currentProperty;
          }
        }

        if (storedProperty) {
          const propertyFromAccess = getPropertyFromAccess(
            storedProperty && storedProperty.id,
            access
          );
          setProperty(propertyFromAccess);
        } else {
          setLoaded(true);
        }
      }
      //If propertyId is set and is the current property, unset it
      if (propertyId && property && property.id == propertyId) {
        setPropertyId("");
        return;
      }

      // If the logged in user is different than the one we have stored, unset what we've stored
      if (user && property && user.id !== property.userId) {
        setProperty(false);
      } else if (access) {
        // If they have a propertyID specified in query params, try it
        if (propertyId) {
          const propertyFromAccess = getPropertyFromAccess(propertyId, access);
          if (propertyFromAccess) {
            setProperty(propertyFromAccess, { forceReload: true });
            // For now, if the specified propertyID isn't one they have access to, ignore it
          } else {
            setPropertyFromStorage();
          }
        } else if (!property) {
          // Take property from local storage if it exists
          setPropertyFromStorage();
        }
      }
    },
    [user, access, propertyId, setProperty, setPropertyId]
  );

  return (
    <Context.Provider
      value={{
        property,
        setProperty,
        loaded,
        resetContext: _ => {
          _setProperty(false);
          setLoaded(false);
        }
      }}
    >
      {loading ? <Loading loading={true} /> : props.children}
    </Context.Provider>
  );
};
export default { Provider, Context };
