import { gql } from "@apollo/client";

export default gql`
  mutation CreateAsset($createAssetInput: CreateAssetInput!) {
    createAsset(createAssetInput: $createAssetInput) {
      id
      __typename
    }
  }
`;
