import React, { useEffect, useContext, useState } from "react";
import { GET_PROPERTY_BY_ID } from "queries";
import { useQuery } from "@apollo/client";
import PropertyContext from "contexts/PropertyContext";

const Context = React.createContext({});

const Provider = function(props) {
  const { property } = useContext(PropertyContext.Context);
  const id = property?.id;
  const { data } = useQuery(GET_PROPERTY_BY_ID, {
    fetchPolicy: "cache-first",
    variables: {
      id
    },
    skip: !id
  });
  return (
    <Context.Provider
      value={{
        property: data?.property ?? props.property
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default { Provider, Context };
