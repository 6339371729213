import React, { useState } from "react";
import { Session } from "components/Main/contexts";
import usePushNotifications from "utils/usePushNotifications";
import Routes from "./routes";
import useTwilio from "utils/useTwilio";
import useAnalytics from "utils/useAnalytics";

const Main = function Main({ globalSnack }) {
  const [prevPage, setPrevPage] = useState(false);

  const [isMobileMenu, setMobileMenu] = useState(false);

  useAnalytics();
  usePushNotifications();

  // init twilio and manage unread messages
  const {
    twilioClient,
    unreadMsgsCount,
    setUnreadMsgsCount,
    calcUnreadMsgsCount
  } = useTwilio();

  return (
    <Session.Provider
      value={{
        prevPage,
        isMobileMenu,
        setPrevPage,
        toggleMobileMenu: setMobileMenu,
        globalSnack,
        twilioClient,
        unreadMsgsCount,
        setUnreadMsgsCount,
        calcUnreadMsgsCount
      }}
    >
      <Routes />
    </Session.Provider>
  );
};

export default Main;
