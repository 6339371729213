import { gql } from "@apollo/client";
// TODO: remove the aliase on active users
export default gql`
  query {
    stats: UsersStats {
      active
      unsent
      pending
      inactive
      archived
    }
  }
`;
