import { gql } from "@apollo/client";

export default gql`
  query GetJobItemById($id: ID!) {
    jobItem: GetJobItemById(id: $id) {
      id
      displayName
    }
  }
`;
