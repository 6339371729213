import { gql } from "@apollo/client";

export default gql`
  query GetAllRoomTypes {
    roomTypes: GetAllRoomTypes {
      id
      displayName
      code
      note
    }
  }
`;
