import React, { Component } from "react";
import Media from "react-media";

import ErrorFallback from "./ErrorFallback";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.handleResetButtonClick = this.handleResetButtonClick.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // log Error to Service
    console.error("ErrorBoundary error:", (error, errorInfo));
  }

  handleResetButtonClick() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError || this.props.notFoundErr) {
      return (
        <div>
          <Media
            queries={{
              small: "(max-width: 599px)",
              medium: "(min-width: 600px) and (max-width: 1199px)",
              large: "(min-width: 1200px)"
            }}
          >
            {matches => (
              <>
                {matches.small && (
                  <ErrorFallback
                    modifiers={"mobile"}
                    handleResetButtonClick={this.handleResetButtonClick}
                  />
                )}
                {matches.medium && (
                  <ErrorFallback
                    modifiers={"tablet"}
                    handleResetButtonClick={this.handleResetButtonClick}
                  />
                )}
                {matches.large && (
                  <ErrorFallback
                    handleResetButtonClick={this.handleResetButtonClick}
                  />
                )}
              </>
            )}
          </Media>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
