import firebase, { firebaseConfiguration } from "FirebaseClient";
import config from "config";
import {
  generateOpenJobUrl,
  getJobAssignedPushNotificationTitle,
  getJobAssignedPushNotificationBody
} from "utils/formatters/jobs";
import { getEscalationNotificationBody } from "utils/formatters/escalations";
import notificationTypes from "utils/constants/notificationTypes";

let messaging;

const badge = `${window.location.origin}/images/badge.png`;
const logo = `${window.location.origin}/images/logo.png`;

async function registerServiceWorker(passthroughConfig) {
  if (isPushNotificationSupported()) {
    messaging = firebase.messaging();
    // Pass state to the sw via query params
    const configParams = Object.getOwnPropertyNames(firebaseConfiguration)
      .map(key => `${key}=${firebaseConfiguration[key]}`)
      .join("&");
    const swUrl = `${config.REACT_APP_UI_URL}/sw.js?${encodeURIComponent(
      configParams
    )}`;

    return registerValidSW(swUrl, passthroughConfig);
  }
}
function registerValidSW(swUrl, config) {
  return navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      messaging.useServiceWorker(registration);
      return registration;
    })
    .catch(error => {
      console.error("Error during service worker registration:", error);
    });
}

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 *
 * Get the token from firebase, then register via address with identity via API
 *
 */
async function createNotificationSubscription(callback) {
  messaging.onTokenRefresh(() => {
    messaging
      .getToken()
      .then(refreshedToken => {
        callback(refreshedToken);
      })
      .catch(err => {
        console.error("Unable to retrieve refreshed token ", err);
      });
  });
  return messaging
    .getToken()
    .then(function(currentToken) {
      if (currentToken) {
        callback(currentToken);
      } else {
        var errMsg = "No Instance ID token available.";
        console.error(errMsg);
      }
    })
    .catch(function(err) {
      console.error("An error occurred while retrieving token. ", err);
    });
}

//What do we do when they are in the app when the notification is coming through?
function handleInAppMessage(registration, t) {
  messaging.onMessage(({ data }) => {
    console.log("Notification Recieved", data);
    const notificationType = getNotificationType(data);
    if (
      notificationType === notificationTypes.privateMessage ||
      notificationType === notificationTypes.tagUser
    ) {
      handleChatMessage(registration, data, t);
    }
    if (notificationType === notificationTypes.jobAssigned) {
      handleJobNotification(registration, data, t);
    }
    if (notificationType === notificationTypes.jobEscalation) {
      handleJobEscalation(registration, data, t);
    }
  });
}

function getNotificationType(data) {
  return data && data.notificationType;
}

async function handleChatMessage(registration, data, t) {
  //If they are in the app but not on this chat thread, need to show notification
  if (
    document.hasFocus() &&
    data.ChannelSid &&
    window.location.href.indexOf(data.ChannelSid) === -1
  ) {
    let previousBody = "";
    const notifications = await registration.getNotifications();
    if (notifications && notifications.length > 0) {
      const channelNotification = notifications.find(
        note => note.data && note.data.ChannelSid === data.ChannelSid
      );
      if (channelNotification && channelNotification.body) {
        previousBody += channelNotification.body;
        channelNotification.close();
      }
    }

    const attributes = JSON.parse(data.Attributes);

    const { twi_body, Content } = data;
    const newBody = Content || twi_body;
    const body = previousBody ? `${previousBody}\n${newBody}` : newBody;

    const url = `${window.location.origin}/messages/${data.ChannelSid}?propertyId=${data.PropertyId}`;
    if (!body || !attributes.sender) {
      console.error("Expected data not present on notificaiton");
      return;
    }

    const title = t(`common:{{userName}} sent you a message`, {
      userName: `${attributes.sender.userFirstName} ${attributes.sender.userLastName}`
    });

    const options = {
      data: {
        ...data,
        url
      },
      body,
      badge: badge,
      icon: logo,
      actions: [{ action: "view", title: t("common:View") }]
    };

    return registration.showNotification(title, options);
  }
}
function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

async function handleJobNotification(registration, data, t) {
  if (
    document.hidden ||
    (document.hasFocus() && window.location.href.indexOf("jobs") !== -1)
  ) {
    return;
  }
  try {
    // WARNING: This is inelegant and generally evil
    // Set a random timeout to assure that various clients don't all try to show a notification at once
    // Longer = longer average time to show a notification, average 1.5 seconds
    // Shorter = greater likelyhood that dups are shown
    const barrier = 4000;
    const time = getRandomInt(barrier);
    setTimeout(_ => {
      const storageKey = `job_notification_${data.twi_message_id}`;
      const current = localStorage.getItem(storageKey);
      if (!current) {
        localStorage.setItem(storageKey, data);
        //Clear the item from localstorage
        setTimeout(_ => {
          localStorage.removeItem(storageKey);
        }, barrier);

        const url = generateOpenJobUrl(data.id, data.propertyId);
        const title = getJobAssignedPushNotificationTitle(data, t);
        const body = getJobAssignedPushNotificationBody(data, t);

        const options = {
          body,
          data: {
            ...data,
            url
          },
          badge: badge,
          icon: `${window.location.origin}/images/priority/${data.priority ||
            "high"}.png`,
          actions: [{ action: "view", title: t("common:View") }],
          tag: data.twi_message_id,
          renotify: true
        };
        return registration.showNotification(title, options);
      }
    }, time);
  } catch (err) {
    console.error("Error!", err);
    return registration.showNotification(
      t("common:You have a new job in Optii")
    );
  }
}

async function handleJobEscalation(registration, data, t) {
  try {
    // WARNING: This is inelegant and generally evil
    // Set a random timeout to assure that various clients don't all try to show a notification at once
    // Longer = longer average time to show a notification, average 1.5 seconds
    // Shorter = greater likelyhood that dups are shown
    const barrier = 4000;
    const time = getRandomInt(barrier);
    setTimeout(_ => {
      const storageKey = `job_escalation_${data.twi_message_id}`;
      const current = localStorage.getItem(storageKey);
      if (!current) {
        localStorage.setItem(storageKey, data);
        //Clear the item from localstorage
        setTimeout(_ => {
          localStorage.removeItem(storageKey);
        }, barrier);

        const url = generateOpenJobUrl(data.jobId, data.propertyId);
        const title = t("common:Action Needed");
        const body = getEscalationNotificationBody(data, t);

        const options = {
          body,
          data: {
            ...data,
            url
          },
          badge: badge,
          icon: logo,
          actions: [{ action: "view", title: t("common:View") }],
          tag: data.twi_message_id,
          renotify: true
        };
        return registration.showNotification(title, options);
      }
    }, time);
  } catch (err) {
    console.error("Error!", err);
    return registration.showNotification(
      t("common:You have a new job in Optii")
    );
  }
}
export {
  isPushNotificationSupported,
  registerServiceWorker,
  createNotificationSubscription,
  handleInAppMessage,
  getNotificationType
};
