const newUI = {
  dustRed06: "#F5222D",

  neutral08: "#595959",

  polarGreen06: "#52C41A"
};

const colors = {
  ...newUI,

  rose: "#CE0000",
  roseLight: "#FC1D1D",
  roseMedium: "#D83333",
  roseDark: "#A10000",
  roseXDark: "#7C0101",
  roseXLight: "#EB9999",
  roseYDark: "#A50000",

  orange: "#F4511D",
  orangeLight: "#FF7549",
  orangeDark: "#C4370B",
  orangeXLight: "#FAC7AB",
  orangeXDark: "#C25B23",

  pink: "#E63E6A",
  pinkDark: "#B72148",
  pinkMedium: "#EB6588",

  purple: "#A046A3",
  purpleXLight: "#e3d1e2",
  purpleLight: "#B26BB6",
  purpleDark: "#842A88",
  purpleXDark: "#502352",

  yellow: "#FBC633",
  yellowDark: "#996E00",
  yellowLight: "#FEEEC1",

  mauve: "#9571AC",
  mauveLight: "#C6A1DE",
  mauveDark: "#775A8A",
  mauveMedium: "#AA8DBD",

  emerald: "#3CA955",
  emeraldLight: "#B7EEA9",
  emeraldDark: "#318344",
  emeraldMed: "#66CE4C",
  emeraldXLight: "#B1DDBB",
  emeraldXXLight: "#E1F5E6",
  emeraldXDark: "#286436",
  emeraldXXXLight: "#D8EEDD",

  persianGreen: "#00B8A1",
  persianGreenMed: "#09A491",
  persianGreenLight: "#3AB6A7",
  persianGreenLighter: "#9DDBD3",

  teal: "#158EB6",
  tealLight: "#CCF2FF",
  tealMed: "#A1D2E2",
  tealDark: "#003A53",
  tealXDark: "#002739",
  tealPrimary: "#61ABC7",
  moderateCyan: "#44a5c5",

  blue: "#2b7c96",
  blueLight: "#c6e0e7",
  blueDark: "#13556B",

  glitter: "#D0E8F0",

  charcoal: "#333333",
  charcoalText: "#434343",
  charcoal06: "#444444",
  charcoal05: "#595959",
  charcoal04: "#A5A5A5",
  charcoal045: "#808080",
  charcoal03: "#CDCDCD",
  charcoal02: "#E5E5E5",
  charcoal01: "#F1F1F1",
  charcoal01opct50: "#f8f8f8",
  charcoal01opct25: "#fcfcfc",
  charcoal45: "#808080",
  charcoalDefault: "#5C5C5C",
  platinum: "#E2E2E2",
  white: "#FFFFFF",
  whiteSmoke: "#F7F7F7",
  bgApp: "#F6F6F6",
  shadow: "#ACAAAA",
  nobel: "#979797",
  taupeGray: "#919190",
  battleshipGrey: "#868686",
  yellow: "#FDE8AD",
  darkYellow: "#FCD15C"
};

export default colors;
