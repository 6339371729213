import { gql } from "@apollo/client";
import { fields as employeeStateFields } from "../employee/getEmployeeState";

export default gql`
  subscription UserLoggedIn(
    $XOptiiUserInfo: String!
    $authToken: String!
    $sessionToken: String
  ) {
    UserLoggedIn(
      XOptiiUserInfo: $XOptiiUserInfo
      authToken: $authToken
      sessionToken: $sessionToken
    ) {
      ${employeeStateFields}
    }
  }
`;
