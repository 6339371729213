import { Plugins, BrowserOpenOptions, Capacitor } from "@capacitor/core";
import { Browser } from "ionic-appauth";
import { isPlatform } from "@ionic/react";

export class OptiiCapacitorBrowser extends Browser {
  public closeWindow(): void | Promise<void> {
    if (!Plugins.Browser) throw new Error("Capacitor Browser Is Undefined!");

    if (Capacitor.platform !== "android") {
      Plugins.Browser.close();
    }
  }

  public async showWindow(url: string): Promise<string | undefined> {
    let options: BrowserOpenOptions = {
      url: url,
      windowName: "_self"
    };

    if (!Plugins.Browser) throw new Error("Capacitor Browser Is Undefined!");

    Plugins.Browser.addListener("browserFinished", (info: any) => {
      this.onCloseFunction();
    });
    if (isPlatform("capacitor")) {
      return new Promise(resolve => {
        Plugins.Browser.open(options);
        Plugins.Browser.addListener("browserPageLoaded", (info: any) => {
          resolve("");
        });
      });
    }
    Plugins.Browser.open(options);
    return;
  }
}
