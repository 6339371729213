import { gql } from "@apollo/client";
export default gql`
  mutation addJob($input: JobInput!) {
    addJob(input: $input) {
      id
      action
      item
      doBy
      status
      locations {
        name
      }
    }
  }
`;
