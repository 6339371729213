import { gql } from "@apollo/client";

export default gql`
  mutation CreateBulkAsset($createBulkAsset: CreateBulkAssetInput!) {
    createBulkAsset(createBulkAsset: $createBulkAsset) {
      id
      __typename
    }
  }
`;
