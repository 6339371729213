import { gql } from "@apollo/client";
export default gql`
  query VerifyUserEmailV2($email: String!) {
    userEmail: VerifyUserEmailV2(email: $email) {
      user
      employee
      invitation
    }
  }
`;
