import { gql } from "@apollo/client";

export default gql`
  query Assets($cursor: String, $first: Int, $Sorts: String, $filters: String) {
    page: assets(
      first: $first
      Sorts: $Sorts
      filters: $filters
      after: $cursor
    ) {
      totalCount
      edges {
        node {
          ... on Asset {
            id
            displayName
            serialNumber
            notes
            status
            location {
              id
              displayName
              title: displayName
              value: id
            }
            assetType {
              id
              displayName
              modelName
              jobItem {
                id
                displayName
              }
              brand {
                id
                displayName
              }
              serviceCycle {
                type
                value
                displayName
              }
            }
            documents {
              uuid
            }
            version
            __typename
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
