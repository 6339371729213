import { gql } from "@apollo/client";

export default gql`
  query GetLocationsMenu {
    locations: GetLocationsMenu {
      id
      displayName
      type
    }
  }
`;
