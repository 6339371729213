import { gql } from "@apollo/client";

export default gql`
  query Reports($first: Int, $after: String, $sorts: String, $filters: String) {
    page: reports(
      first: $first
      after: $after
      sorts: $sorts
      filters: $filters
    ) {
      totalCount
      edges {
        node {
          ... on Report {
            id
            name
            description
            embedKey
            type
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
