import { gql } from "@apollo/client";

export default gql`
  query getLocationById($id: ID!) {
    GetLocationById(id: $id) {
      id
      isActive
      parentId
      isVirtual
      virtualChildrenLocationIds
      parentLocation {
        id
        displayName
        name
        number
      }
      associatedVirtualLocations {
        id
        name
        parentId
      }
      childrenIds
      status
      locationType {
        id
        displayName
        code
        squareUnit
        note
      }
      roomType {
        id
        displayName
        code
        note
      }
      number
      name
      description
      displayName
      class
      squareUnit
      notes
      sequence
      tags {
        name
      }
      __typename
    }
  }
`;
