import React, { useEffect, useContext } from "react";
import { Auth } from "components/Main/Authentication/AuthService";
import { AuthActions } from "ionic-appauth";
import { isPlatform } from "@ionic/react";
import config from "config";
import Loading from "components/shared/Loading";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";

const UI_URL = config.REACT_APP_UI_URL;
const ImplicitContainer = styled.div`
  margin-top: 10rem;
`;

const ImplicitCallback = function ImplicitCallback(props) {
  const {
    setToken,
    routeProps: { location }
  } = props;
  let history = useHistory();
  useEffect(_ => {
    const host = isPlatform("capacitor") ? "optiitopcat://localhost" : UI_URL;
    Auth.Instance.handleCallback(host + location.pathname + location.search);
    const observer = Auth.Instance.addActionListener(async action => {
      console.info("observer firing", action);
      if (
        action.action === AuthActions.SignInSuccess ||
        action.action === AuthActions.LoadTokenFromStorageSuccess
      ) {
        setToken(action.tokenResponse.accessToken);
      }
    });

    return _ => {
      Auth.Instance.removeActionObserver(observer);
    };
  }, []);
  useEffect(
    _ => {
      if (Auth.Instance.session.isAuthenticated) {
        const location = Auth.Instance.getFromUri() || "/dashboard";

        history.push(location);
      }
    },
    [Auth.Instance.session.isAuthenticated]
  );
  return (
    <ImplicitContainer>
      <Loading loading={true} />
    </ImplicitContainer>
  );
};
export default ImplicitCallback;
