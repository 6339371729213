import colors from "../colors";

export default function(options) {
  const color = options.color || colors.charcoal04;
  const borderWidth = options.borderWidth || "4px";
  const rotate = options.rotate || "-140";
  const width = options.width || "17px";
  const height = options.height || "17px";
  return `
  width: ${width};
  height: ${height};
  border-width: ${borderWidth} ${borderWidth} 0 0;
  border-style: solid;
  border-color: ${color};
  margin: 1.7rem 0.5rem;
  transform: rotate(${rotate}deg);
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
  }
`;
}
