import gql from "graphql-tag";

export default gql`
  query GetScheduleAndMetrics(
    $date: Int!
    $departmentId: Int!
    $states: [ScheduleState!]!
  ) {
    results: GetScheduleAndMetrics(
      date: $date
      departmentId: $departmentId
      states: $states
    ) {
      schedules {
        key
        value {
          id
          departmentId
          dateBegin
          dateEnd
          state
          isPublished
          employmentTypesList {
            displayName
            id
            minHoursPerDay
            maxHoursPerDay
            minHoursPerWeek
            maxHoursPerWeek
          }
          roles {
            id
            name
            totalHours
            employees {
              id
              employmentTypeID
              employmentTypeName
              employeeId
              userName
              firstName
              lastName
              emailAddr
              totalHours
              entries {
                id
                employeeID
                employmentTypeID
                departmentID
                roleID
                isAvailabe
                date
                userDatetime
                timeStartSec
                timeStopSec
                hours
                hoursCalculated
              }
            }
          }
        }
      }
      metrics {
        key
        value {
          CPOR
          teamNoShows
          totalScheduled
          totalHours
          occupancy
          departures
          arrivals
          days {
            date
            totalScheduled
            totalHours
          }
        }
      }
    }
  }
`;
