export default [
  { id: "bs", displayName: "Bosnian" },
  { id: "bg", displayName: "Bulgarian" },
  { id: "zh-CN", displayName: "Chinese (Simplified)" },
  { id: "zh-TW", displayName: "Chinese (Traditional)" },
  { id: "en", displayName: "English" },
  { id: "en-AU", displayName: "English (Australian)" },
  { id: "en-NZ", displayName: "English (New Zealand)" },
  { id: "en-GB", displayName: "English (United Kingdom)" },
  { id: "et", displayName: "Estonian" },
  { id: "fr", displayName: "French" },
  { id: "de", displayName: "German" },
  { id: "el", displayName: "Greek" },
  { id: "ht", displayName: "Haitian Creole" },
  { id: "it", displayName: "Italian" },
  { id: "ja", displayName: "Japanese" },
  { id: "mn", displayName: "Mongolian" },
  { id: "pl", displayName: "Polish" },
  { id: "pt-BR", displayName: "Portuguese (Brazil)" },
  { id: "pa", displayName: "Punjabi" },
  { id: "ro", displayName: "Romanian" },
  { id: "ru", displayName: "Russian" },
  { id: "es", displayName: "Spanish (Spain)" },
  { id: "es-MX", displayName: "Spanish (Mexico)" },
  { id: "tl-PH", displayName: "Tagalog" },
  { id: "tr", displayName: "Turkish" },
  { id: "uk", displayName: "Ukrainian" },
  { id: "sq", displayName: "Albanian" },

];
