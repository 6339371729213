import { gql } from "@apollo/client";

export default gql`

query AssetJobs($first: Int, $cursor: String, $sorts: String, $filters: String) {
  page: assetJobs(first: $first, after: $cursor, Sorts: $sorts, filters: $filters) {
    totalCount
    edges {
      node {
        ... on AssetJob {
          id
          displayName
          assetId
          status
          action
          source
          assignee {
            employee {
              id
              firstName
              lastName
            }
            displayName
          }
          departments {
            id
            displayName
            departmentCode
          }
          notes {
            id
            note
            createdAt
          }
          priority
          attachments
        }
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
`;

