import { gql } from "@apollo/client";

export default gql`
  query ListLocationMinimal {
    ListLocationMinimal {
      id
      value: id
      title: name
      pId: parentId
      name
      longDisplayName
      shortDisplayName
      parentName
      locationTypeCode
      locationTypeId
      locationTypeName
      roomTypeId
      roomTypeName
      childCount
    }
  }
`;
