import React, { useState } from "react";
import SnackBar from "components/shared/SnackBar";
// hook for snack bar usage.
export default function useSnack() {
  const [snack, setInnerSnack] = useState(false);
  const setSnack = ({ message, error, timeout = 3000 }) => {
    const code = Date.now();
    setInnerSnack({ message, error, code });
    setTimeout(_ => {
      setInnerSnack(false);
    }, timeout);
  };
  const Snack = _ => (
    <SnackBar
      show={snack}
      modifiers={snack.error && "error"}
      message={snack.message}
    />
  );
  return { setSnack, Snack };
}
