import { gql } from "@apollo/client";

export default gql`
  query GetProjectSummaries(
    $sorts: String
    $filters: String
    $first: Int
    $cursor: String
  ) {
    page: projectSummaries(
      sorts: $sorts
      filters: $filters
      first: $first
      after: $cursor
    ) {
      edges {
        node {
          ... on ProjectSummary {
            id
            displayName
            percentComplete
            percentTimeComplete
            status
            serviceCycle {
              type
              value
              displayName
            }
            currentCycle {
              startDate
              endDate
            }
            nextCycle {
              startDate
              endDate
            }
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
