import { gql } from "@apollo/client";

export default gql`
  mutation addScheduleEmployeesList(
    $scheduleId: ID!
    $input: [CreateScheduleEmployee]!
  ) {
    addScheduleEmployeesList(scheduleId: $scheduleId, input: $input) {
      id
      status
    }
  }
`;
