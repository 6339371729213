import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as Exit } from "images/svg/close.svg";
import CautionButton from "components/shared/buttons/CautionButton";
import Modal from "components/shared/Modal";
import CancelLink from "components/shared/buttons/QuadButton";
import { applyStyleModifiers } from "styled-components-modifiers";
import useKeyDown from "utils/useKeyDown";
import { useTranslation } from "react-i18next";
const CONFIG = {
  fullWidthMobile: ({ theme }) => `
    @media (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
        max-width: 100%;
        min-width: 32rem;
        border-radius: 0;
    }   
  }`,
  wide: ({ theme }) => `
      width: 95%;
      height: 95%;
      min-width: 32rem;
      max-width: ${theme.sizes.modalWide};
  },
  `,
  noPadding: () => `
    padding-left: 0;
    padding-right: 0;
    & ${Header}, & ${Footer} {
      padding: 0 2rem;
    }
  `,
  footerShadow: ({ theme }) => `
    padding-bottom: 0;
    & ${Footer} {
      padding: 2.5rem 2rem;
      background: ${theme.colors.white};
      ${theme.shadows.up};
    }
  `,
  withHeight: () => `
    height: 16.7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `
};

const ConfirmationModal = styled(Modal)`
  max-height: 95%;
  max-width: 95%;
  ${applyStyleModifiers(CONFIG)}
`;

const Close = styled(Exit)`
  align-self: flex-start;
  margin-bottom: 0.8rem;
`;
const H2 = styled.h2`
  text-align: left;
  margin-top: 0;
  display: contents;
  ${props => props.theme.fonts.type.secondary}
  ${props => props.theme.fonts.sizes.extraLarge}
  ${props => props.theme.fonts.weights.weak}
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  ${applyStyleModifiers(CONFIG)}
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

function Confirmation(props) {
  const { t } = useTranslation("common");
  const handleConfirm = async _ => {
    await props.confirm();
    !props.lock && props.hide();
  };
  //Confirm on Enter
  useKeyDown(13, _ => {
    if (props.disabled) return;
    handleConfirm();
  });
  //close on ESC
  useKeyDown(27, _ => {
    props.hide();
  });
  const Button = props.button || CautionButton;
  return (
    <ConfirmationModal
      modifiers={props.modifiers}
      refEl={props.refEl}
      {...props}
    >
      <Header data-testid="confirmationModalHeader">
        <H2>{props.title || t("common:Confirmation")}</H2>
        {!props.showX ? null : (
          <Close
            onClick={e => {
              e.stopPropagation();
              props.hide();
              props.onClose && props.onClose();
            }}
          />
        )}
      </Header>
      {props.children}
      {!props.hideFooter && (
        <Footer>
          {props.cancel || (
            <CancelLink
              onClick={e => {
                e.stopPropagation();
                props.hide();
                props.onClose && props.onClose();
              }}
              data-testid="cancelBtn"
            >
              {t("common:Cancel")}
            </CancelLink>
          )}
          <Button
            onClick={e => {
              e.stopPropagation();
              handleConfirm();
            }}
            disabled={props.disabled}
            modifiers={props.btnModifiers}
            data-testid="confirmation-btn"
          >
            {props.loading
              ? props.loadingMsg || t("common:Loading...")
              : props.action}
          </Button>
        </Footer>
      )}
    </ConfirmationModal>
  );
}
export default Confirmation;
