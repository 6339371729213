import { animated, useTransition } from "react-spring";
import React from "react";

type transitionType = {
  show: any;
  transitions: any;
  children: any;
};
/*
  Helper for simple transitions
*/
function Transition(outerProps: transitionType) {
  const transitions = useTransition(
    outerProps.show,
    null,
    outerProps.transitions
  );

  return (
    <>
      {transitions.map(({ item, key, props }) => (
        <animated.div key={key} style={props}>
          {outerProps.children(item)}
        </animated.div>
      ))}
    </>
  );
}
export default Transition;
