import { gql } from "@apollo/client";

export default gql`
  query AllAssetsIds(
    $cursor: String
    $first: Int
    $Sorts: String
    $filters: String
  ) {
    page: assets(
      first: $first
      Sorts: $Sorts
      filters: $filters
      after: $cursor
    ) {
      edges {
        node {
          ... on Asset {
            id
          }
        }
      }
    }
  }
`;
