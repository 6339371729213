import { gql } from "@apollo/client";

export default gql`
  query FindSchedule(
    $dateBegin: Int!
    $dateEnd: Int!
    $departmentId: Int!
    $states: [ScheduleState!]!
  ) {
    schedule: FindSchedule(
      dateBegin: $dateBegin
      dateEnd: $dateEnd
      departmentId: $departmentId
      states: $states
    ) {
      id
      departmentId
      dateBegin
      dateEnd
      state
      isPublished
      employmentTypesList {
        displayName
        id
      }
      roles {
        id
        name
        totalHours
        employees {
          id
          employmentTypeID
          employmentTypeName
          employeeId
          userName
          firstName
          lastName
          emailAddr
          totalHours
          entries {
            id
            employeeID
            employmentTypeID
            departmentID
            roleID
            isAvailabe
            date
            timeStartSec
            timeStopSec
          }
        }
      }
    }
  }
`;
