import { gql } from "@apollo/client";

export default gql`
  query {
    organizations: ListOrganizations {
      id
      displayName
      parentId
      type
      code
      products
    }
  }
`;
