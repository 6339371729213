import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { Auth } from "components/Main/Authentication/AuthService";
import { AuthActions } from "ionic-appauth";
//returns the decoded token
function useToken() {
  const [token, setToken] = useState("");

  function rawSetToken(value) {
    // Very useful info for debugging token
    // console.info("[raw set Token]", value && jwt_decode(value).sub);
    value
      ? localStorage.setItem("okta-token-storage", value)
      : localStorage.removeItem("okta-token-storage");

    value && setToken(jwt_decode(value));
  }

  useEffect(_ => {
    Auth.Instance.loadTokenFromStorage();

    const observer = Auth.Instance.addActionListener(action => {
      if (
        action.action === AuthActions.SignInSuccess ||
        action.action === AuthActions.LoadTokenFromStorageSuccess
      ) {
        if (action.tokenResponse && action.tokenResponse.isValid(0)) {
          // console.info("[Setting raw token for action]", action.action);
          rawSetToken(action.tokenResponse.accessToken);
        } else {
          Auth.Instance.clearSession();
          window.location.reload();
        }
      } else if (action.action === AuthActions.SignOutSuccess) {
      } else if (action.action === AuthActions.RefreshFailed) {
        // When refresh fails, reload page, tokens are cleared
        window.location.reload();
      }
    });
    return _ => {
      Auth.Instance.removeActionObserver(observer);
    };
  }, []);

  return [token, rawSetToken];
}

export default useToken;
