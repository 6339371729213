const isLocal = typeof window._env_ === "undefined";

//check if runtime variables are defined, if not, use process var
const context = isLocal ? process.env : window._env_;

//For now, checking the graphql server to determine env
function getEnv() {
  const graphServer = context.REACT_APP_GRAPH_SERVER;
  if (graphServer.match(/\.dev\./)) {
    return "DEV";
  } else if (graphServer.match(/\.test\./)) {
    return "TEST";
  } else {
    return "PROD";
  }
}
// A great way of debugging environment related configurations
// console.info("Configuration:", {
//   ...context,
//   ENV: getEnv()
// });
export default {
  ...context,
  ENV: getEnv()
};
