import isString from "lodash/isString";

export const sort = (a, b) => {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
};

export const sortByDisplayName = data => {
  function dispSort(a, b) {
    const dispA = a.displayName.toLowerCase();
    const dispB = b.displayName.toLowerCase();

    return sort(dispA, dispB);
  }
  return data.sort(dispSort);
};

export const sortByParam = (param, data) => {
  function paramSort(a, b) {
    const paramA =
      a[param] && isString(a[param]) ? a[param].toLowerCase() : a[param];
    const paramB =
      b[param] && isString(b[param]) ? b[param].toLowerCase() : b[param];

    return sort(paramA, paramB);
  }
  return data.sort(paramSort);
};

export const sortByTwoParams = (paramA1, paramB1, paramA2, paramB2) => {
  return sort(paramA1, paramB1) || sort(paramA2, paramB2);
};

export const sortByName = (a, b) => {
  if (b.userName == "autoassign") return;
  const aFullName = `${a.userFirstName}${a.userLastName}}`.toLowerCase();
  const bFullName = `${b.userFirstName}${b.userLastName}}`.toLowerCase();
  return sort(aFullName, bFullName);
};

