import { useEffect, useState } from "react";

// insipration at https://usehooks.com/useKeyPress/
// Allows single or array of keys (either 'key' or 'which')
export default function useKeyDown(targetKey, handler) {
  const keys = Array.isArray(targetKey) ? targetKey : [targetKey];
  const [keysDown, setKeysDown] = useState([]);

  // If pressed key (by which or key) fire handler
  function downHandler({ which, key }) {
    const hasKey = keys.includes(key);
    const hasWhich = keys.includes(which);
    if (hasWhich || hasKey) {
      setKeysDown(prev => prev.concat([hasKey ? key : which]));
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key, which }) => {
    const hasKey = keys.includes(key);
    const hasWhich = keys.includes(which);

    if (hasKey || hasWhich) {
      setKeysDown(prev => prev.filter(item => ![which, key].includes(item)));
    }
  };

  useEffect(
    _ => {
      if (!keys.find(ki => !keysDown.includes(ki))) {
        handler();
      }
    },
    [keysDown]
  );

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);
}
