import { gql } from "@apollo/client";
export const fragment = `GetEmployeeByUserId(id: $id) {
  id
  userId
  userName
  userFirstName
  status
  propertyDetails {
    propertyId
    propertyName
    employTypeId
    employTypeName
    roles {
      id
      deptId
      deptName
      roleId
      roleName
      roleStatus
    }
  }
}`;
export default gql`
  query GetEmployeeByUserId($id: ID!) {
    employee: ${fragment}
  }
`;
