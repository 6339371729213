import { gql } from "@apollo/client";

export default gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(createProjectInput: $input) {
      id
      displayName
    }
  }
`;
