import { gql } from "@apollo/client";

export default gql`
  subscription jobUpdated($filters: JobUpdatedFilters) {
    jobUpdated(filters: $filters) {
      id
      item
      action
      priority
      status
      doBy
      completedAt
      locations {
        id
        name
        shortDisplayName
        type
        number
      }
      notes {
        createdBy {
          id
          firstName
          lastName
          userName
        }
        time
        text
      }
      assignee {
        firstName
        lastName
        id
      }
      reporter {
        id
        firstName
        lastName
        userName
      }
      role {
        id
        name
      }
      department {
        id
        displayName
        departmentCode
      }
      periods {
        jobId
        periodStart
        periodEnd
        jobStatus
        assigneeId
        assignee {
          id
          userName
          firstName
          lastName
        }
      }
      attachments
      createdAt
      startedAt
      completedAt
    }
  }
`;
