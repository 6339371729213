import { gql } from "@apollo/client";

export default gql`
  query ListJobEscalationDefs(
    $cursor: String
    $first: Int
    $orderBy: String
    $filters: Map
  ) {
    page: ListJobEscalationDefs(
      filters: $filters
      first: $first
      after: $cursor
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        node {
          ... on JobEscalation {
            PropertyId
            Id
            Name
            Rules {
              JobTypes
              Priorities
              DepartmentIds
              RoleIds
              Actions
            }
            Defs {
              Id
              ActionType
              RoleIds
              ScheduleDef {
                Duration
                OffsetType
              }
              ActionRules {
                IsAssigned
              }
            }
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
