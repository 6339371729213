import { gql } from "@apollo/client";
export default gql`
  mutation UpdateAdhocJobNote(
    $adhocJobNoteInput: AdhocJobNoteInput
    $adhocJobNoteId: String
  ) {
    updateAdhocJobNote(
      adhocJobNoteInput: $adhocJobNoteInput
      adhocJobNoteId: $adhocJobNoteId
    ) {
      commentor {
        employeeId
        employeeSelf
        firstName
        id
        lastName
        userName
      }
      createdAt
      id
      note
    }
  }
`;
