import { gql } from "@apollo/client";

export default gql`
  query ListEmploymentTypes(
    $cursor: String
    $first: Int
    $orderBy: String
    $filters: Map
  ) {
    page: ListEmploymentTypes(
      first: $first
      after: $cursor
      orderBy: $orderBy
      filters: $filters
    ) {
      totalCount
      edges {
        node {
          ... on EmploymentType {
            id
            organisationId
            organisationName
            displayName
            employmentCode
            externalReference
            minHoursPerDay
            maxHoursPerDay
            minHoursPerWeek
            maxHoursPerWeek
            hoursBetweenShifts
            __typename
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
