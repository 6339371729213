import { gql } from "@apollo/client";

export default gql`
  query GetJobsSettings {
    jobsSettings: GetJobsSettings {
      guestRequestMinutes
      internalRequestMinutes
      defaultDepartmentId
    }
  }
`;
