import { gql } from "@apollo/client";

export default gql`
  subscription ScheduleEntriesProcessed(
    $XOptiiUserInfo: String!
    $authToken: String!
  ) {
    ScheduleEntriesProcessed(
      XOptiiUserInfo: $XOptiiUserInfo
      authToken: $authToken
    ) {
      sourceScheduleId
      tagetScheduleId
      action
    }
  }
`;
