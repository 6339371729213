import { gql } from "@apollo/client";
export default gql`
  query GetFileByUUID($id: String!) {
    file: GetFileByUUID(uuid: $id) {
      id
      URL
      name
      createdAt
    }
  }
`;
