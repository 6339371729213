import { gql } from "@apollo/client";

export default gql`
  query ListRoles(
    $cursor: String
    $first: Int
    $orderBy: String
    $filters: Map
  ) {
    page: ListRoles(
      first: $first
      after: $cursor
      orderBy: $orderBy
      filters: $filters
    ) {
      totalCount
      edges {
        node {
          ... on Role {
            id
            name
            accessibleDepts {
              id
              departmentId
              departmentName
            }
            description
            meals {
              isRecurring
              duration
              frequency
            }
            break {
              isRecurring
              duration
              frequency
            }
            isDefault
            payment {
              optionName
              rate
              overtime
              doubletime
              aveRooms
            }
            productAccess {
              product
              permissions {
                id
                key
                name
              }
            }
            __typename
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
