import React from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { applyStyleModifiers } from "styled-components-modifiers";

import CancelLink from "components/shared/buttons/QuadButton";
import PrimaryButton from "components/shared/buttons/PrimaryButton";
import BackgroundImg from "../../../images/svg/fallback/back-error-desktop.svg";
import FallbackImg from "../../../images/svg/fallback/error-desktop.svg";

const MODIFIERS = {
  tablet: () => `
    padding-left: 3rem;
    padding-right: 1rem;
    background-size: 700px 768px, cover;
    p {
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 3rem;
    }
    div {
      justify-content: flex-end;
      margin-right: 2rem;
    }
  `,
  mobile: () => `
    height: calc(100vh - 60px);
    padding-left: 1rem;
    padding-right: 1rem;
    background-size: 440px 480px, cover;
    p {
      font-size: 26px;
      line-height: 28px;
      margin-bottom: 3rem;
    }
    div {
      justify-content: flex-end;
      margin-right: 2rem;
    }
  `
};
const FallbackContainer = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  padding-top: 5rem;
  padding-left: 4.4rem;
  background: url(${FallbackImg}) bottom right no-repeat,
    url(${BackgroundImg}) repeat;
  p {
    font-size: 60px;
    margin-bottom: 3.5rem;
    ${props => props.theme.fonts.type.boundary}
    ${props => props.theme.fonts.weights.strongest}
    color: ${props => props.theme.colors.pink};
  }
  span {
    ${props => props.theme.fonts.weights.light}
    ${props => props.theme.fonts.sizes.largest}
  }
  div {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    button:first-of-type {
      margin-right: 2.5rem;
    }
    button:last-of-type {
      background-color: ${props => props.theme.colors.moderateCyan};
    }
  }
  ${applyStyleModifiers(MODIFIERS)};
`;

const ErrorFallback = ({ handleResetButtonClick, modifiers }) => {
  const history = useHistory();

  return (
    <FallbackContainer modifiers={modifiers}>
      <p>Uh oh... {modifiers && <br />} something has gone wrong</p>
      <span>
        Something has gone wrong and we can't find what you are looking for, you
        can go back or refresh.
      </span>
      <div>
        <CancelLink
          onClick={() => {
            history.goBack();
            handleResetButtonClick && handleResetButtonClick();
          }}
        >
          Go Back
        </CancelLink>
        <PrimaryButton
          onClick={() =>
            handleResetButtonClick ? handleResetButtonClick() : history.go(0)
          }
        >
          Try Again
        </PrimaryButton>
      </div>
    </FallbackContainer>
  );
};

export default ErrorFallback;
