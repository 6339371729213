export default {
  input: `width: 443px;
          height: 32px;`,
  mobileHeaderHeight: "60px",
  tableFirstColumn: "227px",
  printTableFirstColumn: "120px",
  modalWide: "768px",
  timebar: {
    width: 180,
    padding: 10,
    sidebar: 186
  },
  jobCard: {
    defaultWidth: 343,
    margin: 20,
    minWidth: 26
  },
  visiblePartNotStarted: {
    width: 235
  },
  resizablePanel: {
    min: 80,
    max: 375,
    vertical: 25
  },
  stackedJobsPopup: {
    width: 369
  }
};
