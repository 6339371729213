import { gql } from "@apollo/client";

export default gql`
  query AssetTypeJobItems(
    $cursor: String
    $first: Int
    $Sorts: String
    $filters: String
  ) {
    page: assetTypeJobItems(
      filters: $filters
      first: $first
      after: $cursor
      Sorts: $Sorts
    ) {
      totalCount
      edges {
        node {
          ... on JobItem {
            id
            displayName
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
