import { gql } from "@apollo/client";

export default gql`
  query ListUsers(
    $status: UserStatus
    $cursor: String
    $first: Int
    $orderBy: String
  ) {
    page: ListUsers(
      filters: { status: $status }
      first: $first
      after: $cursor
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        node {
          ... on User {
            firstName
            lastName
            emailAddr
            mobileNum
            userName
            id
            status
            __typename
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
