import { gql } from "@apollo/client";

export default gql`
  query ProjectCycleJobs(
    $projectId: ID!
    $projectCycleId: ID!
    $first: Int
    $cursor: String
    $sorts: String
    $filters: String
  ) {
    page: projectCycleJobs(
      projectId: $projectId
      projectCycleId: $projectCycleId
      first: $first
      after: $cursor
      sorts: $sorts
      filters: $filters
    ) {
      totalCount
      edges {
        node {
          ... on ProjectCycleJob {
            id
            jobCardHash
            jobItem
            asset {
              id
              note
              assetType {
                id
                displayName
              }
            }
            status
            assignee {
              employee {
                id
              }
              displayName
            }
            job {
              id
            }
            locations {
              locationId
              displayName
            }
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
