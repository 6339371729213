export default [
  "#594467",
  "#13556B",
  "#EB6588",
  "#A1C23B",
  "#A046A3",
  "#D9B5DA",
  "#3AB6A7",
  "#F58E56",
  "#A12B4A",
  "#B08B24",
  "#c6a1de",
  "#842A88",
  "#FF7549",
  "#ffaaa2",
  "#ea6372",
  "#c32750",
  "#93003a",
  "#b85b8f"
];
