import { gql } from "@apollo/client";
export default gql`
  mutation setJobsSettings($input: JobsSettingsInput!) {
    setJobsSettings(input: $input) {
      guestRequestMinutes
      internalRequestMinutes
      defaultDepartmentId
    }
  }
`;
