import colors from "./colors";
import borders from "./borders";
import shadows from "./shadows";
import fonts from "./fonts";
import sizes from "./sizes";
import zIndex from "./zIndex";
import card from "./elements/card";
import arrow from "./elements/arrow";
import subHeaderMob from "./elements/subHeaderMob";
import breakpoints from "./breakpoints";
import animations from "./animations";
export default {
  colors,
  borders,
  shadows,
  fonts,
  sizes,
  zIndex,
  breakpoints,
  elements: {
    card,
    arrow,
    subHeaderMob
  },
  animations
};
