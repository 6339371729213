import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_EMPLOYEE_STATE, SET_EMPLOYEE_WORKING_STATE } from "queries"; // UPDATE_WORK_INDICATOR
import EmploymentContext from "contexts/EmploymentContext";
import theme from "theme";

const Context = React.createContext({});
const Provider = function(props) {
  const [isWorkingIndicatorOn, setIsWorkingIndicatorOn] = useState(null);
  const [
    isStartWorkingConfirmationShown,
    setIsStartWorkingConfirmationShown
  ] = useState(null);
  const [
    isEndWorkingConfirmationShown,
    setIsEndWorkingConfirmationShown
  ] = useState(null);

  const { employee } = useContext(EmploymentContext.Context);

  const { data } = useQuery(GET_EMPLOYEE_STATE, {
    variables: {
      userID: employee.id
    },
    skip: !employee
  });
  const [updateWorkingIndicator] = useMutation(SET_EMPLOYEE_WORKING_STATE);

  useEffect(() => {
    if (data) {
      // to set initial value
      setIsWorkingIndicatorOn(data.status.working);
    }
  }, [data]);

  const showBorderIndicator = () => {
    if (isWorkingIndicatorOn) {
      return `1px solid ${theme.colors.emerald};`;
    } else {
      return `1px solid ${theme.colors.charcoal04};`;
    }
  };

  return (
    <Context.Provider
      value={{
        isStartWorkingConfirmationShown,
        setIsStartWorkingConfirmationShown,
        isEndWorkingConfirmationShown,
        setIsEndWorkingConfirmationShown,
        isWorkingIndicatorOn,
        setIsWorkingIndicatorOn,
        updateWorkingIndicator,
        showBorderIndicator
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default { Provider, Context };
