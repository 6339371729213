import { gql } from "@apollo/client";

export default gql`
  query {
    properties: ListProperties {
      id
      status
      displayName
      orgId
      orgName
      mgmtCompanyId
      ownerId
      regionalSettings {
        timeZone
        startOfWeek
        language
        logoURL
      }
    }
  }
`;
