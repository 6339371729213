import { gql } from "@apollo/client";

export default gql`
  query FindIndividualSchedule(
    $dateBegin: Int!
    $dateEnd: Int!
    $employeeID: Int64!
  ) {
    schedule: FindIndividualSchedule(
      dateBegin: $dateBegin
      dateEnd: $dateEnd
      employeeID: $employeeID
    ) {
      dateBegin
      dateEnd
      entries {
        id
        employeeID
        employmentTypeID
        departmentID
        roleID
        roleName
        isAvailabe
        date
        userDatetime
        timeStartSec
        timeStopSec
        hours
        hoursCalculated
      }
      totalHours
      schedulesIncluded
    }
  }
`;
