export const details = t => ({
  // Checklists
  access_checklists: {
    displayName: t("rolesAndPermissions:Access Checklist")
  },
  //Schedule
  sch_view: {
    displayName: t("rolesAndPermissions:Access Schedule")
  },
  // Communication
  com_view: {
    displayName: t("rolesAndPermissions:Access Chat")
  },
  // Reports
  report_job_activity_view: {
    displayName: t("rolesAndPermissions:Access Job Activity Report")
  },
  report_team_member_activity_view: {
    displayName: t("rolesAndPermissions:Access Team Member Activity Report")
  },
  report_project_cycle_summary_view: {
    displayName: t("rolesAndPermissions:Access Project Cycle Summary Report")
  },
  report_above_property_access: {
    displayName: t("rolesAndPermissions:Access Above Property Report")
  },
  // Jobs
  job_view: { displayName: t("rolesAndPermissions:Access My Jobs") },
  job_all_view: {
    displayName: t("rolesAndPermissions:Access All Jobs by Status")
  },
  job_autoassign_enable: {
    displayName: t("rolesAndPermissions:Allow Auto-Assignment")
  },
  job_team_member_view: {
    displayName: t("rolesAndPermissions:Access All Jobs by Timeline")
  },
  job_item_autocreate_enable: {
    displayName: t(
      "rolesAndPermissions:Allow Creation of Job Items On Add/Edit Jobs"
    )
  },
  repeating_job_view: {
    displayName: t("rolesAndPermissions:Allow the Management of Repeating Jobs")
  },
  //Settings - Jobs
  set_job_view: {
    displayName: t("rolesAndPermissions:Access Job Settings")
  },
  // Settings - Users
  set_usr_view: {
    displayName: t("rolesAndPermissions:Access Users and Invites")
  },
  // Settings - Users PII
  set_usr_pii_view: {
    displayName: t("rolesAndPermissions:View Personal Information (PII)")
  },
  // Settings - Orgs
  set_org_view: {
    displayName: t("rolesAndPermissions:Access Organizations")
  },
  // Settings - Departments
  set_dpt_view: {
    displayName: t("rolesAndPermissions:Access Departments")
  },
  // Settings - Roles and Permissions
  set_rol_view: {
    displayName: t("rolesAndPermissions:Access Roles")
  },
  // Settings - Employment Types
  set_emp_view: {
    displayName: t("rolesAndPermissions:Access Employment Types")
  },
  // Settings - Floor Plan
  set_floorplan_view: {
    displayName: t("rolesAndPermissions:Access Floor Plan")
  },
  // Settings - Assets
  set_asset_view: {
    displayName: t("rolesAndPermissions:Access Assets")
  },
  // Settings - Projects
  eng_projects_view: {
    displayName: t("rolesAndPermissions:Access Projects")
  }
  // TODO: Uncomment if need heckbox permission for OnShift indicator in Settings
  // Settings - On Shift Indicator
  // on_shift_option_enable: {
  //   displayName: "Access On Shift Option"
  // }
});

const permissions = {
  schedule: {
    any: "sch_*",
    view: "sch_view"
  },
  jobs: {
    any: "job_*",
    view: "job_view",
    all: "job_all_view", // kanban view
    autoassign: "job_autoassign_enable",
    team_member: "job_team_member_view", // team member view
    autocreate: "job_item_autocreate_enable"
  },
  communications: {
    any: "com_*",
    view: "com_view"
  },
  reports: {
    any: "report_*",
    jobActivityView: "report_job_activity_view",
    teamMemberActivityView: "report_team_member_activity_view",
    projectCycleSummaryView: "report_project_cycle_summary_view",
    abovePropertyReportAccess: "report_above_property_access"
  },
  engineering: {
    any: "eng_*",
    accessProjects: "eng_projects_view"
  },
  settings: {
    any: "set_*",
    users: {
      any: "set_usr*",
      view: "set_usr_view",
      pii: "set_usr_pii_view"
    },
    roles: {
      any: "set_rol*",
      view: "set_rol_view"
    },
    orgs: {
      any: "set_org*",
      view: "set_org_view"
    },
    empTypes: {
      any: "set_emp*",
      view: "set_emp_view"
    },
    checklists: {
      any: "checklists*",
      view: "access_checklists"
    },
    departments: {
      any: "set_dpt*",
      view: "set_dpt_view"
    },
    jobs: {
      any: "set_job*",
      view: "set_job_view"
    },
    floorplan: {
      any: "set_floorplan*",
      view: "set_floorplan_view"
    },
    jobItems: {
      any: "set_job*",
      view: "set_job_view"
    },
    repeatingJobs: {
      any: "repeating_job*",
      view: "repeating_job_view"
    },
    onShiftOption: "on_shift_option_enable",
    assets: {
      any: "set_asset*",
      view: "set_asset_view"
    }
  }
};
export default permissions;
