import { gql } from "@apollo/client";
import { fields as employeeStateFields } from "./getEmployeeState";

export default gql`
  query ListTeamMembers(
    $departments: [String!]
    $roles: [String!]
    $employees: [String!]
    $orderBy: String
    $searchQuery: String
    $date: Int64!
    $filters: TeamMembersListFilters
  ) {
    page: ListTeamMembers(
      departments: $departments
      roles: $roles
      employees: $employees
      orderBy: $orderBy
      searchQuery: $searchQuery
      date: $date
      filters: $filters
    ) {
      id
      displayName
      departmentCode
      roles {
        id
        name
        employees {
          id
          employmentTypeID
          employmentTypeName
          employeeId
          userId
          userName
          firstName
          lastName
          emailAddr
          totalHours
          employeeRoleId
          stateData {
            ${employeeStateFields}
          }
          entries {
            id
            employeeID
            employmentTypeID
            departmentID
            roleID
            isAvailabe
            date
            timeStartSec
            timeStopSec
          }
        }
        totalHours
      }
    }
  }
`;
