import { useState, useEffect, useContext } from "react";
import { CREATE_TWILIO_TOKEN } from "queries";
import { Client } from "@twilio/conversations";
import Fingerprint2 from "fingerprintjs2";
import { useMutation } from "@apollo/client";
import UserAccessContext from "contexts/UserAccessContext";
import PropertyContext from "contexts/PropertyContext";
import { Auth } from "components/Main/Authentication/AuthService";

const calcUnreadMsgsCount = async (twilioClient, setUnreadMsgs) => {
  if (!twilioClient) return;
  try {
    const unreadMsgsChannels = {};
    let unreadMsgsCount = 0;
    const channels = await twilioClient.getSubscribedConversations();

    const result = await Promise.all(
      channels.items
        .filter(channel => channel.uniqueName)
        .map(async channelDesc => {
          const channel = await twilioClient.getConversationBySid(
            channelDesc.sid
          );
          const count = await channel.getUnreadMessagesCount();
          return { sid: channelDesc.sid, count };
        })
    );

    result.forEach(({ sid, count }) => {
      unreadMsgsChannels[sid] = count;
      unreadMsgsCount += Number(count);
    });

    setUnreadMsgs(curr => ({
      ...curr,
      ...{
        total: unreadMsgsCount,
        channels: unreadMsgsChannels // using only for redirect user to channel with unread messages
      }
    }));
  } catch (error) {
    console.error("Error", error);
  }
};

const incrementMsgsCount = (message, setUnreadMsgs, identity) => {
  if (identity === message.author) return;
  try {
    setUnreadMsgs(unreadMsgsCount => {
      const newCountTotal = Number(unreadMsgsCount.total) + 1;
      const newCountChannels = Object.keys(unreadMsgsCount.channels).reduce(
        (acc, sid) => {
          if (sid === message.conversation.sid) {
            acc[sid] = Number(unreadMsgsCount.channels[sid]) + 1;
            return acc;
          }
          acc[sid] = unreadMsgsCount.channels[sid];
          return acc;
        },
        {}
      );
      return {
        ...unreadMsgsCount,
        ...{
          total: newCountTotal,
          channels: newCountChannels
        }
      };
    });
  } catch (error) {
    console.error("Error - ", error);
  }
};

function useTwilio() {
  const { user } = useContext(UserAccessContext.Context);
  const { property } = useContext(PropertyContext.Context);
  const [deviceID, setDeviceID] = useState(
    localStorage.getItem("deviceID") || false
  );
  const [twilioClient, setClient] = useState(null);
  const [unreadMsgsCount, setUnreadMsgsCount] = useState(null);

  const [
    createTwilioToken,
    { data: tokenData, loading: tokenLoading, error: tokenError }
  ] = useMutation(CREATE_TWILIO_TOKEN, {
    fetchPolicy: "no-cache",
    context: { _instance: "node" },
    onError(error) {
      console.error(
        "Unable to Create Twilio Token - Ensure Chat is Enabled for this property.",
        error
      );
    }
  });

  const getFingerprint = () => {
    if (deviceID) return;
    Fingerprint2.get(components => {
      const values = components.map(component => component.value);
      const murmur = Fingerprint2.x64hash128(values.join(""), 31);
      localStorage.setItem("deviceID", murmur);
      setDeviceID(murmur);
    });
  };

  if (window.requestIdleCallback) {
    requestIdleCallback(() => {
      getFingerprint();
    });
  } else {
    setTimeout(() => {
      getFingerprint();
    }, 500);
  }

  useEffect(
    _ => {
      if (twilioClient) {
        (async _ => {
          await calcUnreadMsgsCount(twilioClient, setUnreadMsgsCount);
          twilioClient.on("messageAdded", message => {
            incrementMsgsCount(message, setUnreadMsgsCount, user.id);
          });
          twilioClient.on("tokenAboutToExpire", async () => {
            createTwilioToken({
              variables: {
                input: {
                  device: deviceID,
                  identity: user.id
                }
              }
            });
          });
        })();
      }
    },
    [twilioClient, deviceID, user?.id, createTwilioToken]
  );

  useEffect(
    _ => {
      const twilioToken = tokenData && tokenData.createTwilioToken.token;
      if (twilioToken) {
        (async _ => {
          try {
            if (!twilioClient) {
              const convClient = new Client(twilioToken);
              convClient.on("stateChanged", state => {
                if (state === "initialized") {
                  setClient(convClient);
                }
              });
            } else {
              await twilioClient.updateToken(twilioToken);
            }
          } catch (e) {
            console.error(e);
          }
        })();
      }
    },
    [tokenData, twilioClient]
  );

  // fetch twilio token after receiving the user data
  useEffect(
    _ => {
      // Don't get the token if we haven't set up property
      try {
        if (
          user &&
          property &&
          property.userId === user.id &&
          Auth.Instance.isTokenValid(0) &&
          !twilioClient
        ) {
          createTwilioToken({
            variables: {
              input: {
                device: deviceID,
                identity: user.id
              }
            }
          });

          if (twilioClient) {
            twilioClient.removeAllListeners(); // remove all listeners for old instance
            setClient(null); // clear chat instance
            setUnreadMsgsCount(null); // clear msgs count
          }
        }
      } catch (error) {
        console.error("Error", error);
      }
    },
    [deviceID, createTwilioToken, property, user, twilioClient]
  );

  return {
    twilioClient,
    unreadMsgsCount,
    setUnreadMsgsCount,
    calcUnreadMsgsCount
  };
}

export default useTwilio;
