import { gql } from "@apollo/client";

export default gql`
  mutation removeVirtualProjectCycle(
    $projectId: ID!
    $projectCycleHash: String!
    $frequencyHash: String!
  ) {
    removeVirtualProjectCycle(
      projectId: $projectId
      projectCycleHash: $projectCycleHash
      frequencyHash: $frequencyHash
    ) {
      displayName
      startDate
      endDate
      displayStartDate
      displayEndDate
      percentComplete
      percentTimeComplete
      projectCycleStatus
      isDeleted
      id
      version
      frequencyHash
      projectCycleHash
    }
  }
`;
