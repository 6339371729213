import { gql } from "@apollo/client";

export default gql`
  query GetOrganizationById($id: ID!) {
    organization: GetOrganizationById(id: $id) {
      id
      displayName
      parentId
      code
      type
    }
  }
`;
