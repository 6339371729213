import { gql } from "@apollo/client";

export default gql`
  query ListDepartments(
    $cursor: String
    $first: Int
    $orderBy: String
    $filters: Map
  ) {
    page: ListDepartments(
      first: $first
      after: $cursor
      orderBy: $orderBy
      filters: $filters
    ) {
      totalCount
      edges {
        node {
          ... on Department {
            id
            organisationId
            organisationName
            displayName
            departmentCode
            externalReference
            __typename
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
