import { gql } from "@apollo/client";

export default gql`
  query FindEmployeesByDepartment(
    $departmentID: ID!
    $cursor: String
    $first: Int
    $orderBy: String
    $filters: Map
  ) {
    page: FindEmployeesByDepartment(
      departmentID: $departmentID
      after: $cursor
      first: $first
      orderBy: $orderBy
      filters: $filters
    ) {
      totalCount
      edges {
        node {
          ... on EmployeeUser {
            id
            userId
            userName
            userFirstName
            userLastName
            userEmailAddr
            userMobileNum
            status
            propertyDetails {
              id
              propertyId
              propertyName
              employTypeId
              employTypeName
              roles {
                id
                deptId
                deptName
                roleId
                roleName
                roleStatus
              }
            }
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
