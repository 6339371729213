import React from "react";

export const Session = React.createContext({
  user: { id: "" },
  prevPage: false,
  isMobileMenu: false,
  setPrevPage: _ => {},
  toggleMobileMenu: _ => {},
  property: false,
  setProperty: _ => {},
  globalSnack: _ => {},
  token: {}
});
