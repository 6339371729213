import { gql } from "@apollo/client";

export default gql`
  query FindScheduleListBySingleDate(
    $date: Int!
    $departmentId: Int!
    $states: [ScheduleState!]!
  ) {
    schedule: FindScheduleListBySingleDate(
      date: $date
      departmentId: $departmentId
      states: $states
    ) {
      id
      departmentId
      dateBegin
      dateEnd
      state
      isPublished
      employmentTypesList {
        displayName
        id
        minHoursPerDay
        maxHoursPerDay
        minHoursPerWeek
        maxHoursPerWeek
      }
      roles {
        id
        name
        totalHours
        employees {
          id
          employmentTypeID
          employmentTypeName
          employeeId
          userName
          firstName
          lastName
          emailAddr
          totalHours
          entries {
            id
            employeeID
            employmentTypeID
            departmentID
            roleID
            isAvailabe
            date
            userDatetime
            timeStartSec
            timeStopSec
            hours
            hoursCalculated
          }
        }
      }
    }
  }
`;
