import { gql } from "@apollo/client";
export default gql`
  mutation ConvertTaskToJob(
    $convertTaskToJobId: ID!
    $convertTaskToJobInput: ConvertTaskToJobInput
  ) {
    convertTaskToJob(
      checklistTaskId: $convertTaskToJobId
      convertTaskToJobInput: $convertTaskToJobInput
    ) {
      new_job {
        id
        location {
          name
        }
        parent_checklist_task {
          id
        }
        parent_checklist_task_id
      }
    }
  }
`;
