import { gql } from "@apollo/client";

export default gql`
  query ProjectCycles(
    $id: ID!
    $first: Int
    $after: String
    $sorts: String
    $filters: String
  ) {
    page: projectCycles(
      id: $id
      first: $first
      after: $after
      sorts: $sorts
      filters: $filters
    ) {
      totalCount
      edges {
        node {
          ... on ProjectCycle {
            displayName
            startDate
            endDate
            frequencyHash
            projectCycleHash
            displayStartDate
            displayEndDate
            percentComplete
            percentTimeComplete
            projectCycleStatus
            isDeleted
            id
            version
            audit {
              lastModified {
                timestamp
                user {
                  id
                }
              }
              created {
                timestamp
                user {
                  id
                }
              }
            }
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
